import LOCALES from '../locales';

const HU = {
    [LOCALES.HUNGARIAN]: {
        'common':{
            'degreeLevels': {
                'NotSpecified': ' ',
                'Bsc': 'Bsc',
                'Msc': 'Msc',
                'FiveYear': '5 éves képzés',
            },
            'programs': {
                'NotSpecified': ' ',
                'ComputerScience' : 'Mérnökinformatikus',
                'ComputerScienceOperationalEngineering': 'Üzemmérnök',
                'ElectricalEngineer': 'Villamosmérnök',
                'MechanicalEngineering': 'Gépészmérnök',
            },
        },
        'editCourse': {
            'title': 'Tárgy szerkesztése'
        },
        'loginPage': {
            'title': 'Bejelentkezés',
            'label' : {
                'neptun': "Neptun kód",
            },
            'button' : {
                'login': 'Bejelentkezés',
            },
        },
        'mainpage': {
            'title': "TAD portál"
        },
        'university-name': 'Budapest Műszaki és Gazdaságtudományi Egyetem',
        'faculty-name': 'Villamosmérnöki és Informatikai Kar',
        'courses': {
            'table': {
                'header': {
                    'courseId': 'Tantárgykód',
                    'courseName': 'Tantárgynév',
                    'creditNr': 'Kredit',
                    'department': 'Tanszék'
                }
            },
            'label': {
                'search' : 'Keresés',
                'degreeLevel' : 'Képzési szint',
                'program': 'Szak',
                'active' : 'Aktív tárgyak',
                'inactive' : 'Inaktív tárgyak',
                'numOfCourses' : 'Megjelenített kurzusok száma: ',
                'filter': 'Szűrés',
                'ownCourses' : 'Saját tárgyak',
                'allCourses': 'Összes tárgy',
                'withChanges' : 'Változott tárgyak',
            },
            'button':{
                'filter': 'Szűrés',
                'advancedSearch' : 'Keresés adatlapokban'
            },
            'title': 'Tárgyak'
        },
        'navbar': {
            'title': {
                'home': 'Kezdőlap',
                'courses': 'Tárgyak',
                'createCourse': 'Tárgy felvétele',
                'login': 'Bejelentkezés',
                'logout': 'Kijelentkezés',
            },
        },
        'course': {
            'table': {
                'header': {
                    'courseId': 'Tantárgykód',
                    'semester': 'Ajánlott szemeszter',
                    'creditNr': 'Kredit',
                    'requirements': 'Követelmények',
                }
            },
            'page': {
                'language': {
                    'en': 'Angol',
                    'hu': 'Magyar',
                },
                'languageTitle': 'Nyelv',
                'headOfCourse': 'Tárgyfelelős',
                'consultation': 'Konzultációs lehetőségek',
                'instructors': 'A tantárgy előadója',
                'requiredKnowledge': 'A tantárgy az alábbi témakörök ismeretére épít',
                'prerequisites': 'Előtanulmányi rend',
                'objectives': 'A tantárgy célkitűzése',
                'topics': 'A tantárgy részletes tematikája',
                'methodOfInstruction': 'A tantárgy oktatásának módja',
                'assessments': 'Követelmények',
                'recap': 'Pótlási lehetőségek',
                'references': 'Jegyzet, tankönyv, felhasználható irodalom',
                'learningHours': 'A tantárgy elvégzéséhez átlagosan szükséges tanulmányi munka',
                'preparedBy': ' A tantárgy tematikáját kidolgozta',
                'commitMessage': 'Megjegyzés a változtatáshoz',
            }
        },
        'coursePage':{
            'button':{
                'edit': 'Alapadatok szerkesztése',
                'history': 'Adatlap verziók megtekintése',
                'changeLog': 'Változási napló megtekintése',
                'requirements': 'Követelmények',
            }
        },
        'createCourse': {
            'title': 'Tárgy felvétele',
            'form' : {
                'create': 'Létrehozás',
                'edit': 'Mentés',
                'active': 'Kurzus aktiválása',
                'inActive': 'Kurzus inaktiválása',
                'error':{
                    'courseId': 'Tárgykód nem lehet üres vagy már használt'
                },
                'titles': {
                    'courseId' : 'Tárgykód',
                    'degreeLevel' : 'Képzés szintje',
                    'program' : 'Program',
                    'faculty' : 'Kar',
                    'department' : 'Tanszék',
                    'title': 'Tárgynév',
                    'englishTitle' : 'Angol tárgynév',
                    'hours' : 'Heti óraszámok',
                    'lecture': 'Előadás',
                    'practice' : 'Gyakorlat',
                    'laboratory' : 'Labor',
                    'credit': 'Kredit',
                    'template': 'Choose template',
                    'headOfCourse' : 'Tantárgyfelelős',
                },
            },
        },
        'historyPage':{
            'button':{
                'oldVersion': 'Publikus verzió',
                'newVersion': 'Legújabb verzió',
                'compare': 'Verziók összehasonlítása',
                'edit': 'Kurzus szerkesztése',
                'publish': 'Legújabb verzió publikálása',
                'delete': 'Változások törlése',
            },
            'title':{
                'draft': 'Tervezet',
                'public': 'Publikus',
            }
        },
        'comparePage':{
            'error':{
                'incompatible': 'A verziók nem hasonlíthatóak össze a különböző sablonok miatt',
            },
            'title':{
                'from': 'Első verzió',
                'to': 'Másik verzió',
            }
        },
        'advancedSearchPage':{
            'label':{
                'keywords': 'Kulcsszavak',
                'exclude': 'Tiltott kulcsszavak',
                'excludedKeywordsFound': 'Talált tiltott kulcsszavak',
                'keywordsNotFound': 'Nem talált kulcsszavak',
                'matches': 'Találatok',
                'semiMatches': 'Részleges találtatok',
            },
            'button':{
                'search': 'Keresés',
            }
        },
        'changelogs':{
            'label':{
                'message': 'Üzenet',
                'commitMessage': 'Commit üzenet',
                'date': 'Dátum',
            }
        },
        'addVersion':{
            'button':{
                'submit': 'Mentés'
            }
        }
    }
};

export default HU;