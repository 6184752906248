import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditCourseDataV0Component from "../components/dataTemplates/EditCourseDataV0Component";
import DisplayCourseBase from "../components/DisplayCourseBase";
import Config from "../helpers/config";
import { courseService } from "../services/courseService";
import { ApiException, CourseDataV0Model, CourseModel, TemplateVersions } from "../generated/services/Backend";
import { LOCALES } from "../i18n";

export default function AddCourseVersionPage() {
    const { id, lang } = useParams();
    const [course, setCourse] = useState<CourseModel>()
    const [selectedLanguage, setSelectedLanguage] = useState(lang != undefined? (Config.supportedLanguages.find(l => l=lang) ?? LOCALES.HUNGARIAN) : LOCALES.HUNGARIAN);
    const nav = useNavigate();
    const handleSubmit = async (commitMessage: string) => {
        if (!course || id == undefined)
            return;
        if (commitMessage == ""){
            alert("Adjon meg valami megjegyzést!")
            return;
        }
        course.language = selectedLanguage;
        try {
            await courseService.addVersion(course, id, commitMessage)
            nav(`/course/${course.courseId}`)
        } catch (error) {
            if (error instanceof ApiException) {
                alert(JSON.stringify(error.message))
            }
        }
    };
    const renderTemplateComponent = (): JSX.Element | undefined => {
        switch (course?.template) {
            case TemplateVersions.V0:
                return (<EditCourseDataV0Component course={course as any as CourseDataV0Model} setCourse={(data) => setCourse(data)} onSubmit={handleSubmit} />);
        }
    }
    useEffect(() => {
        const loadCourse = async () => {
            if (typeof (id) == "string") {
                try {
                    let response = await courseService.getLatestVersion(id, selectedLanguage);
                    setCourse(response);
                } catch (error) {
                    if (error instanceof ApiException) {
                        if (error.status == 404) {
                            console.log(`Course with id "${id}" not found`);
                        }
                    }
                }
            }
        }
        loadCourse();
    }, [])

    return (<div className="container">
        <div className="row justify-content-center text-center">
            <div className="col">
                <button className={`btn ${selectedLanguage != LOCALES.HUNGARIAN ? 'opacity-50' : ""}`}>
                    <img src='/icons/hu.svg' className='header-lang-icon' alt='Magyar' onClick={() => { setSelectedLanguage(LOCALES.HUNGARIAN) }}></img>
                </button>
            </div>
            <div className="col">
                <button className={`btn ${selectedLanguage != LOCALES.ENGLISH ? 'opacity-50' : ""}`}>
                    <img src='/icons/gb.svg' className='header-lang-icon' alt="English" onClick={() => { setSelectedLanguage(LOCALES.ENGLISH) }}></img>
                </button>
            </div>
        </div>
        {/* <select value={selectedLanguage} onChange={event => setSelectedLanguage(event.target.value)}>
            {Config.supportedLanguages.map(l =>
                <option key={l} value={l}>{l}</option>
            )}
        </select> */}
        {course && <div className="row">
            <h1>{course.title}</h1>
        </div>}
        {course && <DisplayCourseBase course={course} />}
        {course && renderTemplateComponent()}
    </div>)
}