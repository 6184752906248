import { createContext, Dispatch, ReactElement, useEffect, useState } from "react";
import { authService } from "../services/authService";
import { UserModel } from "../generated/services/Backend";
import { Roles } from "./roles";

export const AuthContext = createContext({} as AuthContextProps)

interface AuthContextProps {
    isLoading: boolean
    isAuthenticated: boolean
    setIsAuthenticated : Dispatch<React.SetStateAction<boolean>>
    getCurrentUser : () => void
    currentUser : UserModel | undefined
    isAdmin: boolean
    isLecturer: boolean
}

interface AuthProviderProps {
    children: ReactElement
}
export default function AuthProvider(props: AuthProviderProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLecturer, setIsLecturer] = useState(false);
    const [currentUser, setCurrentUser] = useState<UserModel>();

    useEffect(() => {
        if (isLoading){
            getCurrentUser();
        }else if (isAuthenticated !== false) {
            getCurrentUser();
        }
        else{
            setIsAdmin(false)
            setIsLecturer(false)
        }

    }, [isAuthenticated]);

    const getCurrentUser = async () => {
        try {
            const data = await authService.refresh();
            setIsAuthenticated(data != undefined);
            setCurrentUser(data);
            setIsAdmin(data != undefined && data.role.includes(Roles.Administrator))
            setIsLecturer(data != undefined && data.role.includes(Roles.Lecturer))
        } catch (error) {
            setIsAuthenticated(false);
            setCurrentUser(undefined);
            setIsAdmin(false)
            setIsLecturer(false)
        } finally {
            if (isLoading)
                setIsLoading(false);
        }
    };

    const isInRole = (role : Roles) => {
        return currentUser?.role.includes(role);
    }

    return <AuthContext.Provider value={{ isLoading, isAuthenticated, setIsAuthenticated, getCurrentUser, currentUser, isAdmin, isLecturer }}>{props.children}</AuthContext.Provider>;
}