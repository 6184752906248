import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Translate from "../i18n/messages/Translate";
import { faPenToSquare, faClockRotateLeft, faCalculator } from '@fortawesome/free-solid-svg-icons'
import DisplayCourse from "../components/DisplayCourse";
import { AuthContext } from "../auth/AuthProvider";
import Popup from "reactjs-popup";
import DisplayChangeLogs from "../components/DisplayChangeLogs";
import { ApiException, ChangeLogModel, CourseModel } from "../generated/services/Backend";
import { courseService } from "../services/courseService";
import { LOCALES } from "../i18n";
import Loading from "../components/common/Loading";

export default function CoursePage() {
    const authContext = useContext(AuthContext)
    const [course, setCourse] = useState<CourseModel>()
    const [error, setError] = useState<string | undefined>(undefined);
    const [languages, setLanguages] = useState<string[]>();
    const [selectedLanguage, setSelectedLanguage] = useState(LOCALES.HUNGARIAN);
    const [changeLogs, setChangeLogs] = useState<ChangeLogModel[]>();
    const { id } = useParams();
    const nav = useNavigate();

    const isAllowedToEdit = () => {
        return authContext.isAdmin || (authContext.isLecturer && course?.headOfCourse?.id == authContext.currentUser?.id)
    }

    const loadChanges = async () => {
        if (typeof (id) == "string") {
            try {
                let response = await courseService.getChanges(id)
                setChangeLogs(response)

            } catch (error) {

            }
        }

    }

    useEffect(() => {
        const load = async () => {
            if (typeof (id) == "string") {
                try {
                    let response = await courseService.getCourse(id, selectedLanguage)
                    setCourse(response);

                } catch (error) {
                    if (error instanceof ApiException) {
                        if (error.status == 404) {
                            console.log(`Course with id "${id}" not found`);
                            setError(`Course with id "${id}" not found`);
                        }
                    }
                }
            }
        }
        load()
    }, [selectedLanguage]);

    useEffect(() => {
        const load = async () => {
            if (typeof (id) == "string") {
                try {
                    let langResponse = await courseService.getLanguages(id);
                    setLanguages(langResponse);
                } catch (error) {

                }
            }
        }
        load();
    }, [])

    useEffect(() => {
        if (course) {
            document.title = `TAD portal - ${course?.title}`
        }
    }, [course])

    return (
        <div className="container">
            <Loading condition={course != undefined && error == undefined}>
                <>
                    {error && <p>{error}</p>}
                    {course && <div>
                        <div className="row justify-content-center text-center">
                            <div className="col">
                                {authContext.isAdmin && <button className="btn" style={{ color: "red" }} onClick={() => { nav(`/editCourse/${course.courseId}`) }}><span><FontAwesomeIcon icon={faPenToSquare} /> <Translate id="coursePage.button.edit" /></span></button>}
                            </div>
                            <div className="col">
                                {isAllowedToEdit() && <button className="btn" style={{ color: "blue" }} onClick={() => { nav(`/courseHistory/${course.courseId}/lang/${selectedLanguage}`) }}><FontAwesomeIcon icon={faClockRotateLeft} /> <Translate id="coursePage.button.history" /> </button>}
                            </div>
                            <div className="col">
                                {authContext.isAdmin && <Popup trigger={<button className="btn" > <Translate id="coursePage.button.changeLog" /> </button>} position="right center"
                                    closeOnDocumentClick modal onOpen={() => loadChanges()}><div className="bordered bg-white m-4 p-4">
                                        {changeLogs && <DisplayChangeLogs changeLogs={changeLogs}></DisplayChangeLogs>}
                                    </div></Popup>}
                            </div>
                            <div className="col">
                                {isAllowedToEdit() && <button className="btn" style={{ color: "blue" }} onClick={() => { nav(`/requirements/${course.courseId}`) }}><FontAwesomeIcon icon={faCalculator} /> <Translate id="coursePage.button.requirements" /> </button>}
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col">
                                <div>
                                    <h1 >
                                        {course.title}
                                        {languages && languages?.length > 1 && <>
                                            {selectedLanguage != LOCALES.HUNGARIAN && languages.find(l => l == LOCALES.HUNGARIAN) != undefined && <button className='btn'>
                                                <img src='/icons/hu.svg' className='header-lang-icon' alt='Magyar' onClick={() => { setSelectedLanguage(LOCALES.HUNGARIAN) }}></img>
                                            </button>}
                                            {selectedLanguage != LOCALES.ENGLISH && languages.find(l => l == LOCALES.ENGLISH) != undefined && <button className='btn'>
                                                <img src='/icons/gb.svg' className='header-lang-icon' alt="English" onClick={() => { setSelectedLanguage(LOCALES.ENGLISH) }}></img>
                                            </button>}
                                        </>}
                                    </h1>
                                    {course.englishTitle != undefined && course.englishTitle != '' && <h2>({course.englishTitle})</h2>}
                                </div>
                            </div>
                        </div>
                        <DisplayCourse course={course} />
                    </div>
                    }
                </>
            </Loading>

        </div>
    );
}