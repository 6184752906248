import { flatten } from "flatten-anything";
import { ReactNode, Fragment } from "react";
import { IntlProvider } from "react-intl";
import LOCALES from './locales';
import messages from "./messages";


export default function Provider({children, locale = LOCALES.HUNGARIAN } : {children: ReactNode, locale: string}) {
    return (

        <IntlProvider  locale={locale} textComponent={Fragment} messages={flatten(messages[locale]) as Record<string,string>} defaultLocale={LOCALES.HUNGARIAN} >
            {children}
        </IntlProvider>
    )
}