import { AdvancedSearchRequestModel, AdvancedSearchResultModel, ChangeLogModel, CourseDataV0Model, CourseListItemModel, CourseModel, CreateCourseModel, TemplateVersions, UpdateCourseModel, VersionInfoModel } from "../generated/services/Backend";
import CoursesAPI from "./APIs/CoursesAPI";

class CourseService {

    coursesApi = new CoursesAPI();

    getCourses = async (active: boolean): Promise<CourseListItemModel[]> => {
        return this.coursesApi.getCourses(active);
    }
    getOwnCurses = async (active: boolean): Promise<CourseListItemModel[]> => {
        return this.coursesApi.getOwnCourses(active);
    }
    getCoursesWithChanges = async (): Promise<CourseListItemModel[]> => {
        return this.coursesApi.getCoursesWithUnapprovedChanges();
    }
    getCourseBase = async (courseId: string): Promise<CourseModel> => {
        return this.coursesApi.getCourseBase(courseId);
    }
    getCourse = async (courseId: string, lang: string): Promise<CourseModel> => {
        return this.coursesApi.getCourseWithLanguage(courseId, lang);
    }
    getLanguages = async (courseId: string): Promise<string[]> => {
        return this.coursesApi.getLanguages(courseId);
    }
    isCourseIdValid = async (courseId: string): Promise<boolean> => {
        return this.coursesApi.validateCourseId(courseId);
    }
    createCourse = async (course: CreateCourseModel): Promise<void> => {
        return this.coursesApi.createCourse(course);
    }
    updateCourse = async (course: CourseModel, courseId: string): Promise<void> => {
        return this.coursesApi.updateCourse(UpdateCourseModel.fromJS(course), courseId);
    }
    getVersions = async (courseId: string): Promise<VersionInfoModel[]> => {
        return this.coursesApi.getVersionInfos(courseId);
    }
    getCourseVersion = async (courseId: string, lang: string, version: number): Promise<CourseModel> => {
        return this.coursesApi.getCourseWithVersion(courseId, lang, version);
    }
    getLatestVersion = async (courseId: string, lang: string): Promise<CourseModel> => {
        return this.coursesApi.getLatestCourseWithVersion(courseId, lang);
    }
    addVersion = async (course: CourseModel, courseId: string, message: string): Promise<void> => {
        let request: CourseModel;
        switch (course.template) {
            case TemplateVersions.V0:
                request = CourseDataV0Model.fromJS(course);
                break;
            default:
                return;
        }
        return this.coursesApi.addCourseVersion(request, courseId, message);
    }
    selectPublicVersion = async (courseId: string, lang: string, version: number): Promise<void> => {
        return this.coursesApi.selectPublicVersion(courseId, lang, version);
    }
    revertChanges = async (courseId: string, lang: string) : Promise<void> => {
        return this.coursesApi.revertChanges(courseId,lang);
    }
    getChanges = async (courseId: string): Promise<ChangeLogModel[]> => {
        return this.coursesApi.getChanges(courseId);
    }

    advancedSearch = async (keywords: string, exclude: string): Promise<AdvancedSearchResultModel> => {
        return this.coursesApi.advancedSearch({ keywords: keywords, exclude: exclude } as AdvancedSearchRequestModel)
    }

}

export const courseService = new CourseService();