import { Tooltip } from "react-tooltip"


export interface TimeRequirementItemProps {
    value: number,
    onChange: (name: string, value: string) => void,
    label: string,
    min: number,
    max: number,
    tooltip: string,
    name: string
}

export default function TimeRequirementSingleItem(props: TimeRequirementItemProps) {
    const IsValid = () => { return props.value >= props.min && props.value <= props.max}
    return (
        <td className="text-center">
            <div className="row p-0 m-0">
                <div className="col">
                    <label className="form-label" htmlFor={`input-${props.name}`} id={`label-${props.name}`}><strong>{props.label}</strong></label>
                    <Tooltip anchorSelect={`#label-${props.name}`} place="top">
                        <div>
                            {props.tooltip}
                        </div>
                    </Tooltip>
                </div>
            </div>
            <div className="row justify-content-center">
                <input name={props.name} id={`${props.name}-input`} type="number" className={`form-control ${IsValid() ? '' : 'is-invalid'}`} value={props.value} min={0}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)} style={{ width: "8em" }} ></input>
            </div>

            <div className="row p-0 m-0">
                <span className="form-text">
                    {Math.ceil(props.min)} {"<= x <="} {Math.floor(props.max)}
                </span>
            </div>
        </td>)
}