import { FormEvent, useEffect, useState } from "react";
import { AdvancedSearchResultModel, ApiException } from "../generated/services/Backend";
import { courseService } from "../services/courseService";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
// import { diffWords, diffLines } from "diff";
import Loading from "../components/common/Loading";
import Translate from "../i18n/messages/Translate";

export default function AdvancedSearchPage() {

    const [keywords, setInput1] = useState('');
    const [excludes, setInput2] = useState('');
    const [results, setResults] = useState<AdvancedSearchResultModel>();
    const [loading, setLoading] = useState(false);

    const handleSearch = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            if (keywords != "") {
                setLoading(true)
                let response = await courseService.advancedSearch(keywords, excludes)
                setResults(response)
            }
        } catch (error) {
            if (error instanceof ApiException) {
                console.error(error)
            }
        } finally {
            setLoading(false)
        }
    }

    // useEffect(()=> {
    //     console.log(diffWords("asd asd asd asd", "asd + asd asd"))
    //     console.log(diffLines("asd asd asd asd", "asd + asd asd"))
    // },[])

    return (
    <div className="container mt-5">
        <div className="row">
            <h3>Keresés adatlapban (béta)</h3>
        </div>
        <form onSubmit={handleSearch}>
            <div className="row">
                <div className="col-md-6">
                    <label htmlFor="keywords" className="form-label"><Translate id="advancedSearchPage.label.keywords" />:</label>
                    <input type="text" className="form-control" id="keywords" value={keywords} onChange={(e) => setInput1(e.target.value)} placeholder="kulcszó1;kulcsszó2;..." />
                </div>
                <div className="col-md-6">
                    <label htmlFor="excludes" className="form-label"><Translate id="advancedSearchPage.label.exclude" />:</label>
                    <input type="text" className="form-control" id="excludes" value={excludes} onChange={(e) => setInput2(e.target.value)} placeholder="kulcszó1;kulcsszó2;..." />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-4 text-center">
                    <button className="btn btn-primary mt-4" type="submit"><Translate id="advancedSearchPage.button.search" /></button>
                </div>
            </div>
        </form>
        <Loading condition={!loading}>
            <>
                {results && results.matches.length > 0 && <div className="row mt-5">
                    <div className="col-md-12">
                        <h4><Translate id="advancedSearchPage.label.matches" /></h4>
                        <ul className="list-group">
                            {results.matches.map((result, index) => (
                                <li key={result.courseId + index} className="list-group-item">
                                    <div key={result.courseId} className="card mb-3">
                                        <div className="card-body">
                                            <Link to={`/course/${result.courseId}`} style={{ textDecoration: 'none' }} >
                                                <h5 className="card-title" style={{ color: '#8c2532' }}>{result.title}</h5>
                                                {result.englishTitle && <h6 className="card-title" style={{ color: '#8c2532' }}>{result.englishTitle}</h6>}
                                                <h6 className="card-subtitle mb-2 text-muted">{result.courseId}</h6>
                                            </Link>
                                            <ul className="list-group">
                                                {result.hits.map((hit, index) => (
                                                    <li key={index} className="list-group-item">
                                                        (<i>{hit.field}</i>) <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(hit.text.replace(new RegExp(hit.keyword, "ig"), `<strong>$&</strong>`)) }}></span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>}
                {results && results.semiMatches.length > 0 && <div className="row mt-5">
                    <div className="col-md-12">
                        <h4><Translate id="advancedSearchPage.label.semiMatches" /></h4>
                        <ul className="list-group">
                            {results && results.semiMatches.length > 0 && results.semiMatches.map((result, index) => (
                                <li key={result.courseId + index} className="list-group-item">
                                    <div key={result.courseId} className="card mb-3">
                                        <div className="card-body">
                                            <Link to={`/course/${result.courseId}`} style={{ textDecoration: 'none' }} >
                                                <h5 className="card-title" style={{ color: '#8c2532' }}>{result.title}</h5>
                                                {result.englishTitle && <h6 className="card-title" style={{ color: '#8c2532' }}>{result.englishTitle}</h6>}
                                                <h6 className="card-subtitle mb-2 text-muted">{result.courseId}</h6>
                                            </Link>

                                            {result.keyWordsNotFound.length > 0 && <div>
                                                <Translate id="advancedSearchPage.label.keywordsNotFound" />:
                                                <ul>
                                                    {result.keyWordsNotFound.map(kw => (
                                                        <li key={kw}>{kw}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            }

                                            <ul className="list-group">
                                                {result.hits.map((hit, index) => (
                                                    <li key={index} className="list-group-item">
                                                        (<i>{hit.field}</i>) <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(hit.text.replace(new RegExp(hit.keyword, "ig"), `<strong>$&</strong>`)) }}></span>
                                                    </li>
                                                ))}
                                            </ul>
                                            {result.excludedHits.length > 0 &&
                                                <div>
                                                    <Translate id="advancedSearchPage.label.excludedKeywordsFound" />:
                                                    <ul className="list-group">
                                                        {result.excludedHits.map((hit, index) => (
                                                            <li key={index} className="list-group-item">
                                                                (<i>{hit.field}</i>) <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(hit.text.replace(new RegExp(hit.keyword, "ig"), `<strong>$&</strong>`)) }}></span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>}
            </>
        </Loading>
    </div>)
}