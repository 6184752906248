import { ReactElement, useContext, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import { AuthContext } from "./AuthProvider";

interface ProtectedRouteProps {
    component: ReactElement,
    roles?: string[] | undefined
}

export default function ProtectedRoute(props: ProtectedRouteProps) {
    const authContext = useContext(AuthContext)
    const isAllowed = (): boolean => {
        if (props.roles == undefined)
            return authContext.isAuthenticated
        else {
            return authContext.isAuthenticated && props.roles.some(r => authContext.currentUser?.role.includes(r));
        }
        
    }
    if (!isAllowed() && !authContext.isLoading){
        useEffect(()=>{
            window.location.href="/secure/login";
        },[])
    }
    return (
        <div>
            {authContext.isLoading && <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            }
            {!isAllowed() && !authContext.isLoading && <Navigate to={{pathname:"/secure/login"}} />}
            {isAllowed() && !authContext.isLoading && props.component}
        </div>
    )
}