import { useEffect, useState } from "react";
import { UpdateUserModel, UserModel } from "../generated/services/Backend";
import { userService } from "../services/userService";

export default function UserManagementPage() {
    const [userList, setUserList] = useState<UserModel[]>();
    useEffect(() => {
        const load = async () => {
            try {
                let result = await userService.getUsers()
                setUserList(result)
            } catch (error) {
                console.error(error)
            }
        }
        load()
    }, [])
    const handleCheckboxChange = async (user: UserModel, role: string) => {
        try {
            await userService.updateRole(user.id, role, !user.role.includes(role))
            const roleIndex = user.role.indexOf(role);
            if (roleIndex !== -1) {
                user.role.splice(roleIndex, 1);
            } else {
                user.role.push(role);
            }

            setUserList(userList?.map((value) => {
                if (value.id == user.id)
                    return user;
                return value
            }));
        } catch (error) {
            console.error(error)
        }


    };

    const handleInputChange = (user: any, field: string, text: string) => {
        setUserList(userList?.map(value => {
            if (value.id == user.id) {
                user[field] = text
                return user as UserModel
            }
            return value
        }));
    };

    const handleSaveClick = async (user: UserModel) => {
        await userService.updateUser(user.id, user as UpdateUserModel)
    };


    return (
        <div className="container mt-5">
            <h1>User Management</h1>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Neptun Code</th>
                        <th>Email</th>
                        <th>Actions</th>
                        <th>Roles</th>
                    </tr>
                </thead>
                <tbody>
                    {userList && userList.map((user, index) => (
                        <tr key={index}>
                            <td>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={user.name}
                                    onChange={e => handleInputChange(user, 'name', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={user.neptunCode}
                                    onChange={e => handleInputChange(user, 'neptunCode', e.target.value)}
                                    placeholder="NEPTUN"
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={user.email}
                                    onChange={e => handleInputChange(user, 'email', e.target.value)}
                                    placeholder="Email"
                                />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleSaveClick(user)}
                                >
                                    Save
                                </button>
                            </td>
                            <td>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={user.role.includes('Student')}
                                        onChange={() => handleCheckboxChange(user, 'Student')}
                                    />
                                    <label className="form-check-label">Student</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={user.role.includes('Lecturer')}
                                        onChange={() => handleCheckboxChange(user, 'Lecturer')}
                                    />
                                    <label className="form-check-label">Lecturer</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={user.role.includes('Administrator')}
                                        onChange={() => handleCheckboxChange(user, 'Administrator')}
                                    />
                                    <label className="form-check-label">Administrator</label>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}