import DOMPurify from "dompurify";

interface CompareTwoStringProps {
    oldText: string,
    newText: string
}

export default function CompareTwoString({ oldText, newText }: CompareTwoStringProps) {
    const areEqual = oldText.localeCompare(newText) == 0;
    return (<>
        {areEqual &&
            <div className="col text-start" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newText) }}>
            </div>}
        {!areEqual && <>
            <div className="col-lg text-start" style={{backgroundColor:'#FF5D69'}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(oldText) }}>
            </div>
            <div className="col-lg text-start" style={{backgroundColor:'#5DFF6E'}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newText) }}>
            </div></>}
    </>)
}