import { CourseModel } from "../generated/services/Backend";
import Translate from "../i18n/messages/Translate";

interface DisplayCourseBaseProps {
    course: CourseModel
}

export default function DisplayCourseBase(props: DisplayCourseBaseProps) {
    
    return (<div>
        <div className="row p-2">
            <div>{props.course.department?.parent.name}</div>
            <div>{props.course.department?.name}</div>
        </div>
        <div className="row p-2">
            <div className="col">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th><Translate id='course.table.header.courseId' /></th>
                            {props.course?.suggestedSemester && <th><Translate id='course.table.header.semester' /></th>}
                            <th><Translate id='course.table.header.creditNr' /></th>
                            <th><Translate id='course.table.header.requirements' /></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {props.course?.courseId}
                            </td>
                            {props.course?.suggestedSemester &&
                                <td>
                                    {props.course?.suggestedSemester}
                                </td>}
                            <td>
                                {props.course?.creditNumber}
                            </td>
                            <td>
                                {`${props.course?.lectureNumberPerWeek}/${props.course?.practiceNumberPerWeek}/${props.course?.laboratoryNumberPerWeek}/${props.course.typeOfGrade.toString()[0]}`}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row p-2">
            <div className="col text-end">
                <b><Translate id="course.page.headOfCourse" />:</b>
            </div>
            <div className="col text-start">
                {props.course.headOfCourse?.name}
            </div>
        </div>
        <div className="row">
            <div className="col">

            </div>
        </div>
    </div>)
}