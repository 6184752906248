import { CourseDataV0Model, CourseModel, TemplateVersions } from "../generated/services/Backend";
import CourseDataV0Component from "./dataTemplates/CourseDataV0Component";
import DisplayCourseBase from "./DisplayCourseBase";

interface DisplayCourseProps {
    course: CourseModel
}

export default function DisplayCourse(props: DisplayCourseProps) {
    const renderTemplateComponent = (): JSX.Element | undefined => {
        switch (props.course?.template) {
            case TemplateVersions.V0:
                return (<CourseDataV0Component course={props.course as any as CourseDataV0Model} />);
        }
    }
    return (
        <div>
            <DisplayCourseBase course={props.course} />
            {renderTemplateComponent()}
        </div >
    )
}