import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../auth/AuthProvider";
import Loading from "../components/common/Loading";
import DisplayCourse from "../components/DisplayCourse";
import { courseService } from "../services/courseService";
import { ApiException, CourseDataStatus, CourseModel, VersionInfoModel } from "../generated/services/Backend";
import Translate from "../i18n/messages/Translate";
import { LOCALES } from "../i18n";

export default function HistoryPage() {
    const authContext = useContext(AuthContext);
    const { id, lang } = useParams();
    const [course, setCourse] = useState<CourseModel>()
    const [versions, setVersions] = useState<VersionInfoModel[]>([]);
    const [selectedVersion, setSelectedVersion] = useState<VersionInfoModel>();
    const [hasNewVersion, setHasNewVersion] = useState(false);
    const nav = useNavigate();
    const handlePublish = async () => {
        if (selectedVersion != undefined && selectedVersion.status == CourseDataStatus.WaitingForReview) {
            try {
                let result = confirm("Biztos publikálja a módosításokat?");
                if (result) {
                    await courseService.selectPublicVersion(selectedVersion.courseId, selectedVersion.language, selectedVersion.version);
                    nav(`/course/${course?.courseId}`);
                }
            } catch (error) {
                if (error instanceof ApiException) {
                    alert(error.message)
                }
            }
        }
    }
    const handleDelete = async () => {
        if (selectedVersion != undefined && selectedVersion.status == CourseDataStatus.WaitingForReview) {
            try {
                let result = confirm("Biztos törli a módosításokat?");
                if (result) {
                    await courseService.revertChanges(selectedVersion.courseId, selectedVersion.language);
                    nav(`/course/${course?.courseId}`);
                }
            } catch (error) {
                if (error instanceof ApiException) {
                    alert(error.message)
                }
            }
        }
    }
    const changeVersion = async (status: CourseDataStatus) => {
        if (status == CourseDataStatus.WaitingForReview) {
            let underEdit = versions.sort((d1, d2) => d2.version - d1.version).find(d => d.status == CourseDataStatus.WaitingForReview)
            setSelectedVersion(underEdit);
        }
        else {
            let active = versions.find(d => d.status == CourseDataStatus.Active)
            setSelectedVersion(active)
        }
    }

    useEffect(() => {
        const loadVersions = async () => {
            if (typeof (id) == "string") {
                try {
                    let response = await courseService.getVersions(id)
                    setVersions(response.filter(v => v.language == (lang == undefined ? LOCALES.HUNGARIAN : lang)));
                    setHasNewVersion(response.find(r => r.status == CourseDataStatus.WaitingForReview) != undefined)
                } catch (error) {
                    if (error instanceof ApiException) {
                        if (error.status == 404) {
                            console.log(`Course with id "${id}" not found`);
                        }
                    }
                }
            }
        }
        loadVersions();
    }, []);
    useEffect(() => {
        const loadCourse = async () => {
            if (typeof (id) == "string") {
                try {
                    let response: CourseModel;
                    if (selectedVersion == undefined) {
                        response = await courseService.getLatestVersion(id, (lang == undefined ? LOCALES.HUNGARIAN : lang));
                    }
                    else {
                        response = await courseService.getCourseVersion(id, selectedVersion.language, selectedVersion.version);
                    }
                    setCourse(response);
                } catch (error) {
                    if (error instanceof ApiException) {
                        if (error.status == 404) {
                            console.log(`Course with id "${id}" not found`);
                        }
                    }
                }
            }
        }
        loadCourse();
    }, [selectedVersion])
    return (
        <Loading condition={course != undefined && versions != undefined}>
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col">
                        <button className="btn btn-success" onClick={() => nav(`/addVersion/${course?.courseId}${lang != undefined ? ("/lang/" + lang) : ""}`)}><Translate id="historyPage.button.edit" /></button>
                    </div>
                    <div className="col">
                        <button className="btn btn-secondary" onClick={() => nav(`/compareCourseVersions/${course?.courseId}`)}><Translate id="historyPage.button.compare" /></button>
                    </div>
                    {hasNewVersion && (authContext.isAdmin || authContext.currentUser?.neptunCode == course?.headOfCourse.id) && <>
                        <div className="col">
                            <button className="btn btn-warning" disabled={selectedVersion?.status != CourseDataStatus.WaitingForReview} onClick={() => handlePublish()}><Translate id="historyPage.button.publish" /></button>
                        </div>
                        <div className="col">
                            <button className="btn btn-danger" disabled={selectedVersion?.status != CourseDataStatus.WaitingForReview} onClick={() => handleDelete()}><Translate id="historyPage.button.delete" /></button>
                        </div>
                    </>
                    }
                </div>

                {course && <>
                    <h1>{course!.title} {hasNewVersion && <span className="h4">(
                        <span>
                            <a style={{fontWeight:(selectedVersion?.status == CourseDataStatus.Active ? "bold" : undefined)}} onClick={() => changeVersion(CourseDataStatus.Active)}><Translate id="historyPage.title.public" /></a>/
                            <a style={{fontWeight:(selectedVersion?.status == CourseDataStatus.WaitingForReview ? "bold" : undefined)}} onClick={() => changeVersion(CourseDataStatus.WaitingForReview)}><Translate id="historyPage.title.draft" /></a>
                        </span>
                        )</span>}</h1>
                    <DisplayCourse course={course!} />
                </>
                }
            </div>
        </Loading>
    )
}