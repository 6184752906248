import { DepartmentModel, FacultyModel } from "../generated/services/Backend";
import FacultyAPI from "./APIs/FacultyAPI";

class FacultyService {

    facultyApi = new FacultyAPI();

    getFaculties = async () : Promise<FacultyModel[]> => {
        return this.facultyApi.getFaculties();
    }
    getDepartments = async (facultyId : string) : Promise<DepartmentModel[]> => {
        return this.facultyApi.getDepartments(facultyId);
    }
}
export const facultyService = new FacultyService();