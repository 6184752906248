import { useParams } from "react-router-dom";
import TimeRequirements from "../components/TimeRequirements";


export default function RequirementsPage(){
    const { id } = useParams();
    return (
    <div className="container" style={{ maxWidth: 1000 }}>
        {typeof(id) == "string" && <TimeRequirements courseId={id}/>}
    </div>)
}