import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthProvider";
import Translate from "../i18n/messages/Translate";
import { authService } from "../services/authService";

export default function Login() {
    const [neptun, setNeptun] = useState<string>("");
    const nav = useNavigate();
    const authContext = useContext(AuthContext);
    const handleLogin = async () => {
        nav("/secure/login");
    }
    useEffect(() => { 
        if (authContext.isAuthenticated) {
            nav('/'); 
        }
    }, []);
    
    return (
        <div className="container">
            <div className="row">
                <h1>
                    <Translate id='loginPage.title' />
                </h1>
            </div>
            <div className="row m-4">
                <label htmlFor="neptun" className="col-sm-4 col-form-label"><b><Translate id='loginPage.label.neptun' /></b></label>
                <div className="col-sm-8">
                    <input required type='text' className="form-control" id="neptun" value={neptun} onChange={data => setNeptun(data.target.value)} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <button className="btn btn-primary" type="submit" onClick={handleLogin}><Translate id='loginPage.button.login' /></button>
                </div>
            </div>
        </div>
    )
}