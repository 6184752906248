import { useEffect } from "react";
import { Tooltip } from "react-tooltip"


export interface TimeRequirementDoubleItemProps {
    count: number,
    onCountChange: (name: string, value: string) => void,
    value: number,
    onValueChange: (name: string, value: string) => void,
    countLabel: string,
    valueLabel: string,
    countMin: number,
    countMax?: number | undefined,
    valueMin: number,
    valueMax?: number | undefined
    countToolTip?: string | undefined,
    valueToolTip?: string | undefined,
    countName: string,
    valueName: string,
}

export default function TimeRequirementDoubleItem(props: TimeRequirementDoubleItemProps) {
    const IsCountValid = () => { return props.count >= props.countMin && (props.countMax == undefined ? true : props.count <= props.countMax)}
    const IsValueValid = () => { return props.value >= props.valueMin && (props.valueMax == undefined ? (props.count == 0 ? props.value == 0 : true) : props.value <= props.valueMax)}
    useEffect(() => {
        if (props.count == 0) {
            props.onValueChange(props.valueName, "0")
        }
    }, [props.count])
    return (
        <tr>
            <td>
                <label className="col-form-label" htmlFor={`${props.countName}-input`} id={`label-${props.countName}`}><strong>{props.countLabel}</strong></label>
                {props.countToolTip != undefined && <Tooltip anchorSelect={`#label-${props.countName}`} place="top">
                    <div>
                        {props.countToolTip}
                    </div>
                </Tooltip>}
            </td>
            <td>
                <input name={props.countName} id={`${props.countName}-input`} type="number" className={`form-control ${IsCountValid() ? '' : 'is-invalid'}`} value={props.count} min={0}
                    onChange={(e) => {
                        props.onCountChange(e.target.name, e.target.value);
                    }} style={{ width: "5em" }}></input>
            </td>
            <td>
                <span className="form-text">
                    {Math.ceil(props.countMin)} {"<= x "} {props.countMax != undefined ? `<= ${Math.floor(props.countMax)}` : "  "}
                </span>
            </td>
            <td>
                <label className="col-form-label" htmlFor={`${props.valueName}-input`} id={`label-${props.valueName}`}><strong>{props.valueLabel}</strong></label>
                {props.valueToolTip != undefined && <Tooltip anchorSelect={`#label-${props.valueName}`} place="top">
                    <div>
                        {props.valueToolTip}
                    </div>
                </Tooltip>}
            </td>
            <td>
                <input name={props.valueName} id={`${props.valueName}-input`} type="number" className={`form-control ${IsValueValid() ? '' : 'is-invalid'}`} value={props.value} min={0}
                    onChange={(e) => props.onValueChange(e.target.name, e.target.value)} style={{ width: "5em" }}></input>
            </td>
            <td>
                <span className="form-text">
                    {Math.ceil(props.valueMin)} {"<= x "} {props.valueMax != undefined ? `<= ${Math.floor(props.valueMax)}` : (props.count == 0 ? '<= 0' : '')}
                </span>
            </td>
        </tr>
    )
}