import './App.css';
import { I18nProvider, LOCALES } from './i18n';
import { BrowserRouter, Navigate, Route, Routes, redirect } from 'react-router-dom';
import Header from './components/common/Header';
import CoursesPage from './pages/CoursesPage';
import { useLocalStorage } from './services/useLocalStorage';
import CoursePage from './pages/CoursePage';
import CreateCoursePage from './pages/CreateCoursePage';
import Login from './pages/Login';
import ProtectedRoute from './auth/ProtectedRoute';
import EditCourseBase from './pages/EditCourseBase';
import HistoryPage from './pages/HistoryPage';
import AddCourseVersionPage from './pages/AddCourseVersionPage';
import AuthProvider from './auth/AuthProvider';
import { Roles } from './auth/roles';
import AdvancedSearchPage from './pages/AdvancedSearchPage';
import ComparePage from './pages/ComparePage';
import RequirementsPage from './pages/RequirementsPage';
import UserManagementPage from './pages/UserManagementPage';


function App() {
  const [locale, setLocale] = useLocalStorage("LOCALE", LOCALES.HUNGARIAN);
  return (
    <div className="App">
      <I18nProvider locale={locale}>
        <AuthProvider>
          <BrowserRouter >
            <Header locale={locale} onLanguageChange={(locale) => setLocale(locale)}></Header>
            <Routes>
              <Route path='/'>
                <Route index element={<CoursesPage/>} />
                <Route path='courses' element={<Navigate to="/" />}  />
                <Route path="course/:id" element={<CoursePage />} />
                <Route path='createCourse' element={<ProtectedRoute roles={[Roles.Administrator]} component={<CreateCoursePage />} />} />
                <Route path='editCourse/:id' element={<ProtectedRoute roles={[Roles.Administrator]} component={<EditCourseBase/>}></ProtectedRoute>} />
                <Route path='courseHistory/:id/lang/:lang' element={<ProtectedRoute roles={[Roles.Administrator, Roles.Lecturer]} component={<HistoryPage/>}></ProtectedRoute>} />
                <Route path='addVersion/:id/' element={<ProtectedRoute roles={[Roles.Administrator, Roles.Lecturer]} component={<AddCourseVersionPage/>}></ProtectedRoute>} />
                <Route path='addVersion/:id/lang/:lang' element={<ProtectedRoute roles={[Roles.Administrator, Roles.Lecturer]} component={<AddCourseVersionPage/>}></ProtectedRoute>} />
                <Route path='login' element={<Login />} />
                <Route path='advancedSearch' element={<AdvancedSearchPage/>}/>
                <Route path='compareCourseVersions/:id' element={<ProtectedRoute roles={[Roles.Administrator,Roles.Lecturer]} component={<ComparePage/>}></ProtectedRoute>}/>
                <Route path='requirements/:id' element={<ProtectedRoute roles={[Roles.Administrator, Roles.Lecturer]} component={<RequirementsPage/>}></ProtectedRoute>} />
                <Route path='users' element={<ProtectedRoute roles={[Roles.Administrator]} component={<UserManagementPage/>}/>}/>
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </I18nProvider>
      
    </div>
  );
}

export default App;
