import DOMPurify from 'dompurify';
import Translate from "../../i18n/messages/Translate";
import { CourseDataV0Model } from "../../generated/services/Backend";
import CompareTwoString from '../CompareTwoString';

interface CompareCourseDataV0ToV0ComponentProps {
    oldVersion: CourseDataV0Model,
    newVersion: CourseDataV0Model
}

export default function CompareCourseDataV0ToV0Component({ oldVersion, newVersion }: CompareCourseDataV0ToV0ComponentProps) {


    return (
        <div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.instructors" /></h4>
                    </div>
                </div>
                <div className="row">
                    <CompareTwoString oldText={oldVersion.lecturers} newText={newVersion.lecturers} />
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.requiredKnowledge" /></h4>
                    </div>
                </div>
                <div className="row">
                    <CompareTwoString oldText={oldVersion.requiredKnowledge} newText={newVersion.requiredKnowledge} />
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.prerequisites" /></h4>
                    </div>
                </div>
                <div className="row">
                    <CompareTwoString oldText={oldVersion.preRequisites} newText={newVersion.preRequisites} />
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.objectives" /></h4>
                    </div>
                </div>
                <div className="row">
                    <CompareTwoString oldText={oldVersion.objectives} newText={newVersion.objectives} />
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.topics" /></h4>
                    </div>
                </div>
                <div className="row">
                    <CompareTwoString oldText={oldVersion.topics} newText={newVersion.topics} />
                </div>
            </div>
            <div>
                <div className="row" id="InstructionMethods">
                    <div className="col">

                        <h4><Translate id="course.page.methodOfInstruction" /></h4>
                    </div>
                </div>
                <div className="row">
                    <CompareTwoString oldText={oldVersion.instructionMethods} newText={newVersion.instructionMethods} />
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.assessments" /></h4>
                    </div>
                </div>
                <div className="row">
                    <CompareTwoString oldText={oldVersion.assessments} newText={newVersion.assessments} />
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.recap" /></h4>
                    </div>
                </div>
                <div className="row">
                    <CompareTwoString oldText={oldVersion.recap} newText={newVersion.recap} />
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.consultation" /></h4>
                    </div>
                </div>
                <div className="row">
                    <CompareTwoString oldText={oldVersion.consultation} newText={newVersion.consultation} />
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.references" /></h4>
                    </div>
                </div>
                <div className="row">
                    <CompareTwoString oldText={oldVersion.references} newText={newVersion.references} />
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.learningHours" /></h4>
                    </div>
                </div>
                <div className="row">
                    <CompareTwoString oldText={oldVersion.learningHours} newText={newVersion.learningHours} />
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.preparedBy" /></h4>
                    </div>
                </div>
                <div className="row">
                    <CompareTwoString oldText={oldVersion.preparedBy} newText={newVersion.preparedBy} />
                </div>
            </div>
        </div>
    );
}