import { redirect } from "react-router-dom";
import { Roles } from "../auth/roles";
import { ApiException, UserModel } from "../generated/services/Backend";
import AuthAPI from "./APIs/AuthAPI";

class AuthService {

    user: UserModel | undefined
    authApi: AuthAPI = new AuthAPI()

    constructor() {

    }

    async refresh(): Promise<UserModel | undefined> {
        try {
            let data = await this.authApi.refreshUserInfo();
            if (data != undefined && data != null)
                this.user = data
            else
                this.user = undefined
            
        } catch (error) {
            if (error instanceof ApiException)
                console.error(error.status)
            this.user = undefined;
        }
        return this.user

    }

    isUserAuthenticated(): boolean {
        return this.user != undefined;
    }

    isInRole(role: Roles): boolean {
        return this.user?.role.includes(role) ?? false
    }

    logIn(neptun: string): void {
        redirect("/secure/login");
    }

    async logout(): Promise<void> {
        await this.authApi.logout();
        await this.refresh()
    }
}
export const authService = new AuthService();

