import { TimeRequirementModel } from "../generated/services/Backend";
import RequirementAPI from "./APIs/RequirementAPI";


class RequirementsService{

    requirementsApi = new RequirementAPI()

    async getTimeRequirements(courseId:string) : Promise<TimeRequirementModel>{
        return this.requirementsApi.getTimeRequirement(courseId)
    }

    async addTimeRequirements(courseId:string, data: TimeRequirementModel): Promise<void>{
        return this.requirementsApi.setTimeRequirement(courseId,data)
    }
}

export const requirementsService = new RequirementsService();