import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormCourseBase from "../components/form/FormCourseBase";
import Translate from "../i18n/messages/Translate";
import { ApiException, CreateCourseModel } from "../generated/services/Backend";
import { courseService } from "../services/courseService";


export default function CreateCoursePage() {
    const nav = useNavigate();
    const [course, setCourse] = useState<CreateCourseModel>({} as CreateCourseModel);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        console.log(JSON.stringify(course));
        if (course.department == undefined){
            console.error("Department cannot be empty")
            return;
        }
        if (course.headOfCourse == undefined){
            console.error("Head of course cannot be empty")
            return;
        }
        try {
            var response = await courseService.createCourse(course);
            nav(`/course/${course.courseId}`);
        } catch (error) {
            if (error instanceof ApiException){
                console.log(`Something went wrong`,error)
            }
        }
    }

    return (<div className="container" style={{ maxWidth: 800 }}>
        <div className="row">
            <div className="col">
                <h1><Translate id='createCourse.title' /></h1>
            </div>
        </div>
        <FormCourseBase<CreateCourseModel> course={course} setCourse={ (data) => setCourse(data)} onSubmit={handleSubmit} edit={false} />
    </div>);
}