import LOCALES from '../locales';

const EN = {
    [LOCALES.ENGLISH]: {
        'common': {
            'degreeLevels': {
                'NotSpecified': ' ',
                'Bsc': 'Bsc',
                'Msc': 'Msc',
                'FiveYear': 'Five year'
            },
            'programs': {
                'NotSpecified': ' ',
                'ComputerScience': 'Computer science',
                'ComputerScienceOperationalEngineering': 'Computer science operational engineering',
                'ElectricalEngineer': 'Electrical engineer',
                'MechanicalEngineering': 'Mechanical engineering'
            },
        },
        'editCourse': {
            'title': 'Edit course'
        },
        'loginPage': {
            'title': 'Login',
            'label': {
                'neptun': "Neptun code",
            },
            'button': {
                'login': 'Log in',
            },
        },
        'mainpage': {
            'title': "Course info portal"
        },
        'university-name': 'Budapest University of Technology and Economics',
        'faculty-name': 'Faculty of Electrical Engineering and Informatics',
        'courses': {
            'table': {
                'header': {
                    'courseId': 'Course ID',
                    'courseName': 'Course name',
                    'creditNr': 'Credit',
                    'department': 'Department'
                }
            },
            'label': {
                'search': 'Search',
                'degreeLevel': 'Degree level',
                'program': 'Program',
                'active': 'Active courses',
                'inactive': 'Inactíve courses',
                'numOfCourses': 'Number of courses displayed: ',
                'filter': 'Filter',
                'ownCourses': 'Own courses',
                'allCourses': 'All courses',
                'withChanges': 'Courses with changes',
            },
            'button': {
                'filter': 'Filter',
                'advancedSearch': 'Search in courses'
            },
            'title': 'Courses'
        },
        'navbar': {
            'title': {
                'home': 'Home',
                'courses': 'Courses',
                'createCourse': 'Add course',
                'login': 'Log in',
                'logout': 'Logout',
            },
        },
        'course': {
            'table': {
                'header': {
                    'courseId': 'Course ID',
                    'semester': 'Suggested semester',
                    'creditNr': 'Credit',
                    'requirements': 'Requirements',
                }
            },
            'page': {
                'language': {
                    'en': 'English',
                    'hu': 'Hungarian',
                },
                'languageTitle': 'Language',
                'headOfCourse': 'Head of course',
                'consultation': 'Consultation',
                'instructors': 'Instructors',
                'requiredKnowledge': 'Required knowledge',
                'prerequisites': 'Pre-requisites',
                'objectives': 'Objectives, learning outcomes and obtained knowledge',
                'topics': 'Synopsis',
                'methodOfInstruction': 'Method of instruction',
                'assessments': 'Assessment',
                'recap': 'Recaps',
                'references': 'References, textbooks and resources',
                'learningHours': 'Required learning hours and assignment',
                'preparedBy': 'Syllabus prepared by',
                'commitMessage': 'Note for the change',
            }
        },
        'coursePage': {
            'button': {
                'edit': 'Edit base data',
                'history': 'See course versions',
                'changeLog': 'See changelogs',
                'requirements': 'Requirements',
            }
        },
        'createCourse': {
            'title': 'Add course',
            'form': {
                'create': 'Create',
                'edit': 'Save',
                'active': 'Make course active',
                'inActive': 'Make course inactive',
                'error': {
                    'courseId': 'Course ID can not be empty or already used'
                },
                'titles': {
                    'courseId': 'Course ID',
                    'degreeLevel': 'Degree level',
                    'program': 'Program',
                    'faculty': 'Faculty',
                    'department': 'Department',
                    'title': 'Course name',
                    'englishTitle': 'English course name',
                    'hours': 'Hours per week',
                    'lecture': 'Lecture',
                    'practice': 'Practice',
                    'laboratory': 'Laboratory',
                    'credit': 'Credit',
                    'template': 'Choose template',
                    'headOfCourse': 'Head of Course',
                },
            },
        },
        'historyPage': {
            'button': {
                'oldVersion': 'Public version',
                'newVersion': 'Newest version',
                'compare': 'Compare versions',
                'edit': 'Edit course',
                'publish': 'Publish newest version',
                'delete': 'Delete changes',
            },
            'title': {
                'draft': 'Draft',
                'public': 'Public',
            }
        },
        'comparePage': {
            'error': {
                'incompatible': 'Versions cannot be compared do to the different template versions',
            },
            'title': {
                'from': 'Compare version',
                'to': 'to version',
            }
        },
        'advancedSearchPage': {
            'label': {
                'keywords': 'Keywords',
                'exclude': 'Exclude',
                'excludedKeywordsFound': 'Excluded keywords found:',
                'keywordsNotFound': 'Keywords not found',
                'matches': 'Matches',
                'semiMatches': 'Partial matches',
            },
            'button': {
                'search': 'Search',
            }
        },
        'changelogs':{
            'label':{
                'message': 'Message',
                'commitMessage': 'Commit message',
                'date': 'Date',
            }
        },
        'addVersion':{
            'button':{
                'submit': 'Save'
            }
        }
    }
};

export default EN;