import { ChangeEvent, Dispatch, FormEventHandler, useEffect, useState } from "react";
import Translate from "../../i18n/messages/Translate";
import SelectDegreeLevel from "./SelectDegreeLevel";
import SelectUniversityProgram from "./SelectUniversityProgram";
import { CourseModel, DepartmentModel, FacultyModel, PersonModel, UserModel } from "../../generated/services/Backend";
import { courseService } from "../../services/courseService";
import { facultyService } from "../../services/facultyService";
import { userService } from "../../services/userService";

interface FormCourseBaseProps<T extends CourseModel> {
    course: T
    setCourse: Dispatch<T>
    onSubmit: FormEventHandler<HTMLFormElement>
    edit: boolean
}

export default function FormCourseBase<T extends CourseModel>(props: FormCourseBaseProps<T>) {
    const [faculties, setFaculties] = useState<FacultyModel[]>();
    const [departments, setDepartments] = useState<DepartmentModel[]>([])
    const [selectedFaculty, setSelectedFaculty] = useState<FacultyModel | undefined>(props.course.department?.parent)
    const [allowEnglishTitle, setAllowEnglishTitle] = useState<boolean>(props.course.englishTitle != '');
    const [lecturers, setLecturers] = useState<PersonModel[]>([]);
    const [timer, setTimer] = useState<number | undefined>();
    const [isCourseIdValid, setIsCourseIdValid] = useState(true)

    const checkCourseId = async (courseId: string) => {
        let result = await courseService.isCourseIdValid(courseId);
        if (typeof (result) != 'string') {
            setIsCourseIdValid(result);
        }
    }

    const onCourseIdInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.setCourse({ ...props.course, courseId: event.target.value });
        if (timer)
            window.clearTimeout(timer);
        setTimer(window.setTimeout(() => { checkCourseId(event.target.value) }, 1000))
    }

    const onStringInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.setCourse({ ...props.course, [event.target.name]: event.target.value })
    }

    const onNumberInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.setCourse({ ...props.course, [event.target.name]: Number(event.target.value) })
    }

    useEffect(() => {
        const loadFaculties = async () => {
            var response = await facultyService.getFaculties();
            if (typeof (response) != 'string') {
                setFaculties(response)
                if (faculties && faculties.length > 0) {
                    setSelectedFaculty(faculties[0])
                }
            }
            else {
                console.log(response)
            }
        };
        const loadLecturers = async () => {
            var response = await userService.getLecturers();
            if (typeof (response) != 'string') {
                response = response.sort((a,b) => a.name.localeCompare(b.name))
                setLecturers(response)
            }
            else {
                console.log(response)
            }
        };
        loadFaculties();
        loadLecturers();
    }, []);

    useEffect(() => {
        const load = async () => {
            if (!selectedFaculty)
                return;
            var response = await facultyService.getDepartments(selectedFaculty.facultyId);
            if (typeof (response) != 'string') {
                setDepartments(response)
                if (departments && departments.length > 0) {
                    props.course.department = departments[0];
                }
            }
            else {
                console.log(response)
            }
        };
        load();
    }, [selectedFaculty]);
    return (
        <form onSubmit={props.onSubmit}>
            <div className="mb-3 row">
                <label htmlFor="courseId" className="col-sm-4 col-form-label"><Translate id='createCourse.form.titles.courseId' /></label>
                {props.edit &&
                    <div className="col-sm-8">
                        <input required type='text' className='form-control' id="courseId" value={props.course.courseId} disabled />
                    </div>
                }
                {!props.edit &&
                    <div className="col-sm-8">
                        <input required type='text' className={`form-control ${props.edit || isCourseIdValid ? '' : 'is-invalid'}`} id="courseId" value={props.course.courseId} onChange={onCourseIdInputChange} />
                        <div className="invalid-feedback">
                            <Translate id='createCourse.form.error.courseId' />
                        </div>
                    </div>
                }
            </div>
            <div className="mb-3 row">
                <label htmlFor="title" className="col-sm-4 col-form-label"><Translate id='createCourse.form.titles.title' /></label>
                <div className="col-sm-8">
                    <div className="input-group">
                        <input required type='text' className="form-control" name="title" id="title" value={props.course.title} onChange={onStringInputChange} />
                        <div className="input-group-text">
                            <div className="form-switch">
                                <input className="form-check-input p-2" type="checkbox" id="allowEnglish" checked={allowEnglishTitle} onChange={event => { setAllowEnglishTitle(!allowEnglishTitle) }} />
                                <img className="p-1" src='/icons/gb.svg' style={{ width: 30 }}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {allowEnglishTitle &&
                <div className="mb-3 row">
                    <label htmlFor="englishTitle" className="col-sm-4 col-form-label"><Translate id='createCourse.form.titles.englishTitle' /></label>
                    <div className="col-sm-8">
                        <input type='text' className="form-control" name="englishTitle" id="englishTitle" value={props.course.englishTitle ?? ''} onChange={onStringInputChange} />
                    </div>
                </div>
            }
            <div className="mb-3 row">
                <label htmlFor="degreeLevel" className="col-sm-4 col-form-label"><Translate id='createCourse.form.titles.degreeLevel' /></label>
                <div className="col-sm-8">
                    <SelectDegreeLevel value={props.course.degreeLevel} setValue={(data) => props.setCourse({ ...props.course, degreeLevel: data })} />
                </div>
            </div>
            <div className="mb-3 row">
                <label htmlFor="program" className="col-sm-4 col-form-label"><Translate id='createCourse.form.titles.program' /></label>
                <div className="col-sm-8">
                    <SelectUniversityProgram value={props.course.program} setValue={(data) => props.setCourse({ ...props.course, program: data })} />
                </div>
            </div>
            {faculties && faculties.length > 0 && <div className="mb-3 row">
                <label htmlFor="faculty" className="col-sm-4 col-form-label"><Translate id='createCourse.form.titles.faculty' /></label>
                <div className="col-sm-8">
                    <select className="form-select" id="faculty" value={props.course.department?.parent.facultyId != undefined ? props.course.department?.parent.facultyId : selectedFaculty?.facultyId ?? ""}
                        onChange={event => {
                            let faculty = faculties.find(f => f.facultyId == event.target.value)
                            setSelectedFaculty(faculty);
                            props.setCourse({ ...props.course, department: undefined })
                        }}>
                        <option value=""></option>
                        {faculties.map(f =>
                            <option key={f.facultyId} value={f.facultyId}>{f.name} ({f.facultyId})</option>
                        )};
                    </select>
                </div>
            </div>}
            {(selectedFaculty || props.course.department?.parent.facultyId != undefined) && departments && departments.length > 0 && <div className="mb-3 row">
                <label htmlFor="department" className="col-sm-4 col-form-label"><Translate id='createCourse.form.titles.department' /></label>
                <div className="col-sm-8">
                    <select className="form-select" id="department" value={props.course.department?.id ?? ""} onChange={event => { props.setCourse({ ...props.course, department: departments.find(d => d.id.toString() == event.target.value) }) }}>
                        <option value=""></option>
                        {departments.map(d =>
                            <option key={d.id} value={d.id}>{d.name} ({d.shortName})</option>
                        )};
                    </select>
                </div>
            </div>}
            {lecturers && lecturers.length > 0 && <div className="mb-3 row">
                <label htmlFor="lecturers" className="col-sm-4 col-form-label"><Translate id='createCourse.form.titles.headOfCourse' /></label>
                <div className="col-sm-8">
                    <select className="form-select" id="lecturers" value={props.course.headOfCourse?.id ?? ""} onChange={event => { props.setCourse({ ...props.course, headOfCourse: lecturers.find(d => d.id == event.target.value)! }) }}>
                        <option key="" value=""></option>
                        {lecturers.map(d =>
                            <option key={d.id} value={d.id}>{d.name}</option>
                        )};
                    </select>
                </div>
            </div>}
            <div className="mb-3 row">
                <label className="form-label" htmlFor="lectureNr"><Translate id="createCourse.form.titles.hours" /></label>
                <div className="col ">
                    <div className="input-group justify-content-center">
                        <span className="input-group-text"><Translate id="createCourse.form.titles.lecture" /></span>
                        <input className="form-control" type="number" id="lectureNr" min={0} name='lectureNumberPerWeek' value={props.course.lectureNumberPerWeek} onChange={onNumberInputChange} style={{ maxWidth: 75 }} />
                        <span className="input-group-text"><Translate id="createCourse.form.titles.practice" /></span>
                        <input className="form-control" type="number" id="practiceNr" min={0} name='practiceNumberPerWeek' value={props.course.practiceNumberPerWeek} onChange={onNumberInputChange} style={{ maxWidth: 75 }} />
                        <span className="input-group-text"><Translate id="createCourse.form.titles.laboratory" /></span>
                        <input className="form-control" type="number" id="laboratoryNr" min={0} name='laboratoryNumberPerWeek' value={props.course.laboratoryNumberPerWeek} onChange={onNumberInputChange} style={{ maxWidth: 75 }} />
                        <span className="input-group-text"><Translate id="createCourse.form.titles.credit" /></span>
                        <input className="form-control" type="number" id="creditNr" min={0} name='creditNumber' value={props.course.creditNumber} onChange={onNumberInputChange} style={{ maxWidth: 75 }} />
                    </div>
                </div>
            </div>
            <div className="mb-3 row justify-content-center text-center">
                <div className="col">
                    <button className={`btn ${props.course.isActive ? 'btn-danger' : 'btn-success'}`} type="button" onClick={() => { props.setCourse({ ...props.course, isActive: !props.course.isActive }) }}>
                        {!props.course.isActive && <Translate id="createCourse.form.active" />}
                        {props.course.isActive && <Translate id="createCourse.form.inActive" />}
                    </button>
                </div>
                <div className="col">
                    <button type="submit" className="btn btn-primary">
                        {!props.edit && <Translate id="createCourse.form.create" />}
                        {props.edit && <Translate id="createCourse.form.edit" />}
                    </button>
                </div>

            </div>
        </form>
    )
}