import './header.css';
import Translate from '../../i18n/messages/Translate';
import { LOCALES } from '../../i18n';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../auth/AuthProvider';
import { authService } from '../../services/authService';

interface HeaderProps{
    locale: string
    onLanguageChange: (locale: string) => void
}

export default function Header({ locale ,onLanguageChange }: HeaderProps) {
    const authContext = useContext(AuthContext);
    const handleLogout = async () => {
        await authService.logout();
        if (!authService.isUserAuthenticated())
            authContext.setIsAuthenticated(false);
    }
    return (
        <div>
            <header id="header" className='mb-4' style={{ backgroundImage: `url('/images/header_pic.jpg')` }}>
                <div className='row text-center m-0'>
                    <div className='col-md '>
                        <Link to={'/'}>
                            <img src='/images/bme_logo.gif' alt='BME Logo'></img>
                        </Link>
                    </div>
                    <div className='col-md' id='header-title'>
                        <div className='row'>
                            <div className='col'>
                                <Translate id='university-name' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <Translate id='faculty-name' />
                            </div>
                        </div>
                    </div>
                    <div className='col-md d-flex'>
                        {locale != LOCALES.HUNGARIAN && <button className='btn'>
                            <img src='/icons/hu.svg' className='header-lang-icon' alt='Magyar' onClick={() => { onLanguageChange(LOCALES.HUNGARIAN) }}></img>
                        </button>}
                        {locale != LOCALES.ENGLISH &&<button className='btn'>
                            <img src='/icons/gb.svg' className='header-lang-icon' alt="English" onClick={() => { onLanguageChange(LOCALES.ENGLISH) }}></img>
                        </button>}
                        {!authContext.isAuthenticated && process.env.NODE_ENV == 'production' &&
                            <a href="/secure/login" className='btn text-center my-auto text-white'><Translate id='navbar.title.login' /></a>
                        }
                        {!authContext.isAuthenticated && process.env.NODE_ENV == 'development' &&
                            <a href="/token?neptunCode=H1UJBZ&returnUrl=/" className='btn text-center my-auto text-white'><Translate id='navbar.title.login' /></a>
                        }
                        {authContext.isAuthenticated &&
                            <Link to={'/'} className='btn text-center my-auto text-white' onClick={handleLogout}><Translate id='navbar.title.logout' /></Link>
                        }
                    </div>
                </div>
            </header>
        </div>
    )
}

