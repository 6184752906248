import { ChangeLogModel } from "../generated/services/Backend"
import Translate from "../i18n/messages/Translate"

interface DisplayChangeLogsProps {
    changeLogs: ChangeLogModel[]
}

export default function DisplayChangeLogs({ changeLogs }: DisplayChangeLogsProps) {

    return (
        <div className="position-relative">
            <ul className="list-group">
                {changeLogs.map((logItem, index) => (
                    <li key={index} className="list-group-item">
                        <div className="d-flex justify-content-between">
                            <div>
                                <div className="font-weight-bold"><Translate id="changelogs.label.date"/>:</div>
                                <div>{logItem.createdDate.toLocaleString()}</div>
                            </div>
                            {logItem.commitMessage && (
                                <div className="text-muted">
                                    <div className="font-weight-bold"><Translate id="changelogs.label.commitMessage"/>:</div>
                                    <div>{logItem.commitMessage}</div>
                                </div>
                            )}
                        </div>
                        <div className="mt-2">
                            <div className="font-weight-bold"><Translate id="changelogs.label.message"/>:</div>
                            <div>{logItem.message}</div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>)
}