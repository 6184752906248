import { FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../auth/AuthProvider";
import FormCourseBase from "../components/form/FormCourseBase";
import Translate from "../i18n/messages/Translate";
import { courseService } from "../services/courseService";
import { ApiException, CourseModel } from "../generated/services/Backend";

export default function EditCourseBase() {
    const authContext = useContext(AuthContext)
    const { id } = useParams();
    const [course, setCourse] = useState<CourseModel>()
    const nav = useNavigate();
    useEffect(() => {
        const load = async () => {

            if (typeof (id) == "string"){
                try {
                    setCourse(await courseService.getCourseBase(id))
                } catch (error) {
                    if (error instanceof ApiException){
                        if (error.status == 404){
                            console.log(`Course with id "${id}" not found`);
                        }
                    }
                }
            }
        }
        load()
    }, []);



    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        if (course?.department == undefined) {
            console.error("Department cannot be empty")
            return;
        }
        if (course?.headOfCourse == undefined) {
            console.error("Head of course cannot be empty")
            return;
        }
        if (id) {
            try {
                await courseService.updateCourse(course, id);
                nav(`/course/${id}`)
            } catch (error) {
                if (error instanceof ApiException) {
                    console.log(`Something went wrong`, error)
                }
            }
        }

    }

    return (
        <div className="container" style={{ maxWidth: 800 }}>
            <div className="row justify-content-center text-center m-4">
                <div className="col">
                    <h1><Translate id='editCourse.title' /></h1>
                </div>
            </div>
            <div className="row">
                {course &&
                    <FormCourseBase<CourseModel> course={course} setCourse={setCourse} edit={true} onSubmit={handleSubmit} />
                }
            </div>
        </div>
    );
}