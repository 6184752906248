import { PersonModel, UpdateUserModel, UserModel } from "../generated/services/Backend";
import UserAPI from "./APIs/UserAPI";

class UserService {

    userApi = new UserAPI();

    getLecturers= async () : Promise<PersonModel[] > => {
        return this.userApi.getLecturers()
    }

    getUsers = async() : Promise<UserModel[]> => {
        return this.userApi.getUsers()
    }

    createUser = async(model: UpdateUserModel) : Promise<void> => {
        this.userApi.addUser(model)
    }

    updateUser = async(id : string, model: UpdateUserModel) : Promise<void> => {
        this.userApi.updateUser(id,model)
    }

    updateRole = async(userId: string, roleName: string, add:boolean): Promise<void> => {
        this.userApi.updateUserRole(userId,roleName,add)
    }
}
export const userService = new UserService();