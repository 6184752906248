import { Editor } from "@tinymce/tinymce-react";
import DOMPurify from "dompurify";

interface AdvancedTextInputProps{
    value : string,
    onValueChange : (value : string) => void
}

export default function AdvancedTextInput(props : AdvancedTextInputProps){
    return (
        <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code',
                        'insertdatetime', 'media', 'table', 'preview', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                value={props.value}
                onEditorChange={(value : string) => props.onValueChange(DOMPurify.sanitize(value))}/>
    )
}