import DOMPurify from 'dompurify';
import Translate from "../../i18n/messages/Translate";
import { CourseDataV0Model } from "../../generated/services/Backend";

interface CourseDataV0ComponentProps{
    course: CourseDataV0Model
}

export default function CourseDataV0Component(props: CourseDataV0ComponentProps) {


    return (
        <div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.instructors" /></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-start" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.lecturers) }}>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.requiredKnowledge" /></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.requiredKnowledge) }}>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.prerequisites" /></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.preRequisites) }}>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.objectives" /></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.objectives) }}>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.topics" /></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.topics) }}>
                    </div>
                </div>
            </div>
            <div>
                <div className="row" id="InstructionMethods">
                    <div className="col">
                        <h4><Translate id="course.page.methodOfInstruction" /></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.instructionMethods) }}>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.assessments" /></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.assessments) }}>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.recap" /></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.recap) }}>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.consultation" /></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.consultation) }}>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.references" /></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.references) }}>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.learningHours" /></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.learningHours) }}>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                        <h4><Translate id="course.page.preparedBy" /></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.preparedBy) }}>
                    </div>
                </div>
            </div>
        </div>
    );
}