import { ReactElement } from "react";

interface LoadingProps {
    children: ReactElement,
    condition: boolean,
    error?: boolean,
    errorComponent?: ReactElement
}

export default function Loading(props: LoadingProps) {
    return (
        <>
            {props.condition && !props.error && props.children}
            {!props.condition && !props.error && <div className="m-2 d-flex justify-content-center">
                <div className="spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            {props.error &&
                props.errorComponent}
        </>
    )
}