import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiException, CourseDataV0Model, CourseModel, TemplateVersions, VersionInfoModel } from "../generated/services/Backend";
import { courseService } from "../services/courseService";
import CompareCourseDataV0ToV0Component from "../components/dataTemplates/CompareCourseDataV0ToV0Component";
import Translate from "../i18n/messages/Translate";
import Loading from "../components/common/Loading";

export default function ComparePage() {

    const { id } = useParams();
    const [versions, setVersions] = useState<VersionInfoModel[]>([]);
    const [oldVersion, setOldVersion] = useState<VersionInfoModel>();
    const [newVersion, setNewVersion] = useState<VersionInfoModel>();
    const [oldCourse, setOldCourse] = useState<CourseModel>();
    const [newCourse, setNewCourse] = useState<CourseModel>();
    useEffect(() => {
        const loadVersions = async () => {
            if (typeof (id) == "string") {
                try {
                    let response = await courseService.getVersions(id)
                    setVersions(response);
                } catch (error) {
                    if (error instanceof ApiException) {
                        if (error.status == 404) {
                            console.log(`Course with id "${id}" not found`);
                        }
                    }
                }
            }
        }
        loadVersions();
    }, []);
    useEffect(() => {
        const loadOldVersion = async () => {
            if (oldVersion != undefined) {
                try {
                    let response = await courseService.getCourseVersion(oldVersion.courseId, oldVersion.language, oldVersion.version);
                    setOldCourse(response)
                } catch (error) {

                }
            }
            else {
                setOldCourse(undefined)
            }
        }
        loadOldVersion();
    }, [oldVersion])
    useEffect(() => {
        const loadOldVersion = async () => {
            if (newVersion != undefined) {
                try {
                    let response = await courseService.getCourseVersion(newVersion.courseId, newVersion.language, newVersion.version);
                    setNewCourse(response)
                } catch (error) {

                }
            }
            else {
                setNewCourse(undefined)
            }
        }
        loadOldVersion();
    }, [newVersion])
    const renderTemplateComponent = (): JSX.Element | undefined => {
        switch (oldCourse?.template) {
            case TemplateVersions.V0:
                if (newCourse?.template == TemplateVersions.V0) {
                    return (<CompareCourseDataV0ToV0Component oldVersion={oldCourse as CourseDataV0Model} newVersion={newCourse as CourseDataV0Model} />)
                }
                return (<p><Translate id='comparePage.error.incompatible' /></p>);
        }
    }
    return (
        <div className="container">
            <Loading condition={versions != undefined} >
                <div className="row">
                    <div className="col">
                        <h5 className="text-center"><Translate id="comparePage.title.from" /></h5>
                        <select className="form-control" value={oldVersion == undefined ? "" : versions?.indexOf(oldVersion)}
                            onChange={event => setOldVersion(event.target.value == "" ? undefined : versions[Number(event.target.value)])}>
                            <option value=""></option>
                            {versions?.map((v, i) =>
                                <option key={i} value={i}>{v.language} {v.version} {v.status}</option>
                            )}
                        </select>
                    </div>
                    <div className="col">
                        <h5 className="text-center"><Translate id="comparePage.title.to" /></h5>
                        <select className="form-control" value={newVersion == undefined ? "" : versions?.indexOf(newVersion)}
                            onChange={event => setNewVersion(event.target.value == "" ? undefined : versions[Number(event.target.value)])}>
                            <option value=""></option>
                            {versions?.map((v, i) =>
                                <option key={i} value={i}>{v.language} {v.version} {v.status}</option>
                            )}
                        </select>
                    </div>
                </div>

            </Loading>

            <div className="row">
                {oldCourse && newCourse && renderTemplateComponent()}
            </div>
        </div>
    );
}