import { Dispatch } from "react";
import Translate from "../../i18n/messages/Translate";
import { UniversityPrograms } from "../../generated/services/Backend";

interface SelectUniversityProgramProps{
    value: UniversityPrograms | undefined
    setValue : Dispatch<UniversityPrograms | undefined>
}

export default function SelectUniversityProgram(props: SelectUniversityProgramProps){

    return (<select className="form-select" id="universityProgram" value={props.value ?? UniversityPrograms[UniversityPrograms.NotSpecified]} onChange={event => {
        let program: UniversityPrograms | undefined = event.target.value as unknown as UniversityPrograms;
        props.setValue(program);
    }}>
        {Object.keys(UniversityPrograms).filter(k => isNaN(Number(k))).map(f =>
            <option key={f} value={f}><Translate id={`common.programs.${f}`} /></option>
        )};
    </select>)
}