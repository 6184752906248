import { FormEvent, useEffect, useState } from "react"
import { ApiException, GradeType, TimeRequirementModel } from "../generated/services/Backend"
import { requirementsService } from "../services/requirementsService";
import Loading from "./common/Loading";
import { Tooltip } from "react-tooltip";
import TimeRequirementSingleItem from "./TimeRequirementSingleItem";
import TimeRequirementDoubleItem from "./TimeRequirementDoubleItem";
import { useNavigate } from "react-router-dom";

export interface TimeRequirementsProps {
    courseId: string
}

export default function TimeRequirements({ courseId }: TimeRequirementsProps) {
    const [courseData, setCourseData] = useState<TimeRequirementModel>();
    const [remaining, setRemaining] = useState(0)
    const [balance, setBalance] = useState(0)
    const nav = useNavigate();
    useEffect(() => {
        const load = async () => {
            let result = await requirementsService.getTimeRequirements(courseId)
            setCourseData(result)
        }
        load();
    }, [])
    useEffect(() => {
        if (courseData != undefined) {
            let first = (courseData.creditNumber * 30) -
                ((courseData.lectureNumberPerWeek + courseData.practiceNumberPerWeek + courseData.laboratoryNumberPerWeek) * 14) -
                courseData.prepareForLecture - courseData.prepareForPractice - courseData.prepareForLaboratory -
                courseData.prepareForExam - courseData.timeForSelfStudy;
            let rem = first -
                courseData.prepareForSmallTests - courseData.prepareForTests -
                courseData.timeForSmallHomeworks - courseData.timeForHomeworks -
                courseData.prepareForPresentations - courseData.timeForEssays -
                courseData.prepareForOtherTests;
            setBalance(rem)
            setRemaining(
                rem >= 0 ? rem : 0
            )
        }

    }, [courseData])
    const valueChange = (name: string, value: string) => {
        setCourseData({ ...courseData, [name]: Number(value) } as TimeRequirementModel)
    }
    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (balance != 0) {
            if (balance > 0) {
                alert("Van még nem kiosztott óra")
            }
            else {
                alert("Túl sok óra van kiosztva")
            }
        }
        else {
            if (courseData != undefined) {
                try {
                    await requirementsService.addTimeRequirements(courseId, courseData)
                    nav("/courses")
                }
                catch (e) {
                    console.log(e)
                    if (e instanceof ApiException) {
                        alert(e.message)
                    }
                }
            }

        }

    }
    return (<>
        <Loading condition={courseData != undefined}>
            <>{courseData && <form onSubmit={onSubmit}>
                <div className="row text-center">
                    <div className="col">
                        <h1>
                            {courseData.title}
                        </h1>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col">
                        <h4>
                            {courseData.lectureNumberPerWeek}/{courseData.practiceNumberPerWeek}/{courseData.laboratoryNumberPerWeek}/{courseData.typeOfGrade.charAt(0)}/{courseData.creditNumber}
                        </h4>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col">
                        <label id="total"><strong>Teljes munkamennyiség:</strong> {courseData.creditNumber * 30}</label>
                        <Tooltip anchorSelect="#total" place="top">Kreditszám * 30</Tooltip>
                    </div>
                    <div className="col">
                        <label id="contact"><strong>Kontaktóra:</strong> {(courseData.lectureNumberPerWeek + courseData.practiceNumberPerWeek + courseData.laboratoryNumberPerWeek) * 14}</label>
                        <Tooltip anchorSelect="#contact" place="top">Heti óraszám * 14</Tooltip>
                    </div>
                </div>
                <table className="table">
                    <tr>
                        <TimeRequirementSingleItem label="Készülés előadásokra:" min={(courseData.lectureNumberPerWeek * 14 * 0.2)} max={courseData.lectureNumberPerWeek * 14 * 0.5}
                            name="prepareForLecture" onChange={valueChange} tooltip="Heti óraszám * 14 * {0,2..0,5}" value={courseData.prepareForLecture} />
                        <TimeRequirementSingleItem label="Készülés gyakorlatra:" min={(courseData.practiceNumberPerWeek * 14 * 0.5)} max={courseData.practiceNumberPerWeek * 14 * 1}
                            name="prepareForPractice" onChange={valueChange} tooltip="Heti óraszám * 14 * {0,5..1}" value={courseData.prepareForPractice} />
                        <TimeRequirementSingleItem label="Készülés laborra:" min={(courseData.laboratoryNumberPerWeek * 14 * 0.5)} max={courseData.laboratoryNumberPerWeek * 14 * 1}
                            name="prepareForLaboratory" onChange={valueChange} tooltip="Heti óraszám * 14 * {0,5..1}" value={courseData.prepareForLaboratory} />
                    </tr>
                    <tr>


                        <TimeRequirementSingleItem label="Vizsgafelkészülés:" min={courseData.typeOfGrade != GradeType.Exam ? 0 : courseData.creditNumber * 8} max={courseData.typeOfGrade != GradeType.Exam ? 0 : courseData.creditNumber * 10}
                            name="prepareForExam" onChange={valueChange} tooltip={courseData.typeOfGrade != GradeType.Exam ? "Nem vizsgás tárgy" : "Kreditszám * {8..10}"} value={courseData.prepareForExam} />
                        <TimeRequirementSingleItem label="Önálló tananyag-feldolgozás:" min={0} max={courseData.creditNumber * 30}
                            name="timeForSelfStudy" onChange={valueChange} tooltip="A tantárgyfelelős által meghatározott munkaráfordítás" value={courseData.timeForSelfStudy} />
                    </tr>
                </table>







                <hr></hr>
                <table className="table">
                    <TimeRequirementDoubleItem valueLabel="Felkészülési idő:" count={courseData.numberOfSmallTests} countLabel="KisZH darabszáma:" countMin={0} countMax={14}
                        countName="numberOfSmallTests" countToolTip="10..30 perc; min. 3 db; órán írják" onCountChange={valueChange} onValueChange={valueChange}
                        value={courseData.prepareForSmallTests} valueMin={courseData.numberOfSmallTests * 2} valueMax={courseData.numberOfSmallTests * 3} valueName="prepareForSmallTests"
                        valueToolTip="2-3 óra/kisZH" />

                    <TimeRequirementDoubleItem valueLabel="Felkészülési idő:" count={courseData.numberOfTests} countLabel="NagyZH darabszáma:" countMin={0} countMax={courseData.typeOfGrade == GradeType.Exam ? 1 : 2}
                        countName="numberOfTests" countToolTip="vizsga esetén: max. 1 nagyZH; félévközinél: max. 2 nagyZH" onCountChange={valueChange} onValueChange={valueChange}
                        value={courseData.prepareForTests} valueMin={courseData.numberOfTests * courseData.creditNumber * 3} valueMax={courseData.numberOfTests * courseData.creditNumber * 4} valueName="prepareForTests"
                        valueToolTip="45..100 perc; ha órán kívül írják, beleszámít az időkeretbe" />

                    <TimeRequirementDoubleItem valueLabel="Elkészítési idő:" count={courseData.numberOfSmallHomeworks} countLabel="Kis HF darabszáma:" countMin={0}
                        countName="numberOfSmallHomeworks" onCountChange={valueChange} onValueChange={valueChange}
                        value={courseData.timeForSmallHomeworks} valueMin={courseData.numberOfSmallHomeworks * 1} valueMax={courseData.numberOfSmallHomeworks * 16} valueName="timeForSmallHomeworks"
                        valueToolTip="max. 16 óra / HF" />

                    <TimeRequirementDoubleItem valueLabel="Elkészítési idő:" count={courseData.numberOfHomeworks} countLabel="Nagy HF darabszáma:" countMin={0}
                        countName="numberOfHomeworks" onCountChange={valueChange} onValueChange={valueChange}
                        value={courseData.timeForHomeworks} valueMin={courseData.numberOfHomeworks * 16} valueName="timeForHomeworks"
                        valueToolTip="min. 16 óra, de legalább 20 óra ajánlott" />

                    <TimeRequirementDoubleItem valueLabel="Felkészülési idő:" count={courseData.numberOfPresentations} countLabel="Kiselőadás darabszáma:" countMin={0}
                        countName="numberOfPresentations" onCountChange={valueChange} onValueChange={valueChange}
                        value={courseData.prepareForPresentations} valueMin={courseData.numberOfPresentations * 16} valueMax={courseData.numberOfPresentations * 48} valueName="prepareForPresentations"
                        valueToolTip="min. 15 perc; 16..48 óra felkészülési idő" />

                    <TimeRequirementDoubleItem valueLabel="Elkészítési idő:" count={courseData.numberOfEssays} countLabel="Tanulmány, esszé darabszáma:" countMin={0}
                        countName="numberOfEssays" onCountChange={valueChange} onValueChange={valueChange}
                        value={courseData.timeForEssays} valueMin={courseData.numberOfEssays * 16} valueMax={courseData.numberOfEssays * 48} valueName="timeForEssays"
                        valueToolTip="min. 10 oldal; 16..48 óra felkészülési idő" />
                    <TimeRequirementDoubleItem valueLabel="Felkészülési idő:" count={courseData.numberOfOtherTests} countLabel="Ellenőrző mérés:" countMin={0}
                        countName="numberOfOtherTests" onCountChange={valueChange} onValueChange={valueChange}
                        value={courseData.prepareForOtherTests} valueMin={0} valueName="prepareForOtherTests"
                        valueToolTip="kb. 16 óra felkészülési idő" />
                </table>
                <div className="row text-center">
                    <div className="col">
                        <h5>
                            <strong>Maradék órák száma: </strong>{balance}
                        </h5>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col">
                        <button type="submit" className="btn btn-primary" disabled={balance != 0}>Submit</button>
                    </div>
                </div>

            </form>}
            </>
        </Loading>
    </>)
}