import { useEffect, useState } from "react";

function getStorageValue(key: string, defaultValue: any): any {
    const saved = localStorage.getItem(key);
    return saved || defaultValue;
}

export const useLocalStorage = (key: string, defaultValue: any) : [string,React.Dispatch<React.SetStateAction<string>>] => {
    const [value, setValue] = useState<string>(() => { return getStorageValue(key, defaultValue); });
    useEffect( () => { localStorage.setItem(key, value ); }, [key,value] );
    return[value,setValue];
}