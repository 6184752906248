import { ChangeEvent, useContext, useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Translate from "../i18n/messages/Translate";
import { courseService } from "../services/courseService";
import { CourseListItemModel, DegreeLevels, UniversityPrograms } from "../generated/services/Backend";
import SelectDegreeLevel from "../components/form/SelectDegreeLevel";
import SelectUniversityProgram from "../components/form/SelectUniversityProgram";
import { AuthContext } from "../auth/AuthProvider";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function CoursesPage() {
    const [queryParams, setQueryParams] = useSearchParams();

    const [courses, setCourses] = useState<CourseListItemModel[]>();
    const [listedCourses, setListedCourses] = useState<CourseListItemModel[]>();
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [timer, setTimer] = useState<number | undefined>();
    const [filterActive, setIsActive] = useState<boolean>(queryParams.get('active') == null ? true : queryParams.get('active') == 'true')
    const [selectedDegreeLevel, setSelectedDegreeLevel] = useState<DegreeLevels | undefined>(queryParams.get('degreeLevel') == null ? undefined : queryParams.get('degreeLevel')! as unknown as DegreeLevels); //TODO: Check validity
    const [selectedProgram, setSelectedProgram] = useState<UniversityPrograms | undefined>(queryParams.get('program') == null ? undefined : queryParams.get('program')! as unknown as UniversityPrograms); //TODO: Check validity
    const [selectedDepartment, setSelectedDepartment] = useState<string | undefined>(queryParams.get('department') == null ? undefined : queryParams.get('department')!);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filterOwn, setFilterOwn] = useState(false);
    const [filterChanges, setFilterChanges] = useState(false);
    const authContext = useContext(AuthContext)
    const nav = useNavigate();
    let listedCourseNumber: number = 0;

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (timer)
            window.clearTimeout(timer);
        setTimer(window.setTimeout(() => { setSearchKeyword(event.target.value) }, 500))
    }

    const filterCourses = (courses: CourseListItemModel[]) => {
        let tempCourseList = courses;
        if (searchKeyword != undefined && searchKeyword != "") {
            tempCourseList = tempCourseList.filter(course => course.courseId.toLowerCase().includes(searchKeyword.toLowerCase()) || course.title.toLowerCase().includes(searchKeyword.toLowerCase()) || course.englishTitle?.toLowerCase().includes(searchKeyword.toLowerCase()) || course.department?.toLowerCase().includes(searchKeyword.toLowerCase()));
        }
        if (selectedDegreeLevel != undefined && selectedDegreeLevel != DegreeLevels.NotSpecified) {
            tempCourseList = tempCourseList.filter(course => course.degreeLevel == selectedDegreeLevel);
        }
        if (selectedProgram != undefined && selectedProgram != UniversityPrograms.NotSpecified) {
            tempCourseList = tempCourseList.filter(course => course.universityProgram == selectedProgram);
        }
        if (selectedDepartment != undefined) {
            tempCourseList = tempCourseList.filter(course => course.department == selectedDepartment);
        }
        setListedCourses(tempCourseList);
    }

    useEffect(() => {
        if (courses != undefined)
            filterCourses(courses)
        let params: any = { active: String(filterActive) };
        if (selectedDegreeLevel != undefined && selectedDegreeLevel != DegreeLevels.NotSpecified)
            params["degreeLevel"] = selectedDegreeLevel;
        if (selectedProgram != undefined && selectedProgram != UniversityPrograms.NotSpecified)
            params["program"] = selectedProgram;
        if (selectedDepartment != undefined && selectedDepartment != "")
            params["department"] = selectedDepartment;
        setQueryParams(params)
    }, [courses, searchKeyword, selectedDegreeLevel, selectedDepartment, selectedProgram])

    useEffect(() => {
        const load = async (): Promise<void> => {
            let response: CourseListItemModel[] | string;
            if (filterChanges) {
                response = await courseService.getCoursesWithChanges();
            }
            else if (filterOwn) {
                response = await courseService.getOwnCurses(filterActive);
            }
            else {
                response = await courseService.getCourses(filterActive)
            }
            if (typeof (response) == 'string') {
                console.log(response);
                setError(response)
            }
            else {
                response = response.sort((a,b) => a.title.localeCompare(b.title))
                setCourses(response as CourseListItemModel[]);
            }
            document.title = 'TAD portal';
            setQueryParams({ active: String(filterActive) })
        }
        load();
    }, [filterActive, filterOwn, filterChanges])
    return (
        <div className="container">
            {!courses && !error && <div className="d-flex justify-content-center">
                <div className="spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            {error && <p>{error}</p>}
            {courses && <div>
                <div className="row text-center">
                    <h1><Translate id="courses.title" /></h1>
                </div>
                <div className="row m-4 justify-content-center">
                    <div className="col-md-6">
                        <div className="row">
                            <label htmlFor="search" className="col-sm-2 col-form-label"><b><Translate id='courses.label.search' /></b></label>
                            <div className="col-sm-10">
                                <input required type='text' className="form-control" id="search" onChange={onInputChange} />
                            </div>
                        </div>

                    </div>
                    <div className="col-md-3 text-center ">
                        <button className="btn" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                            <span>
                                <Translate id="courses.button.filter" /> {!isFilterOpen && <FontAwesomeIcon icon={faChevronDown} />} {isFilterOpen && <FontAwesomeIcon icon={faChevronUp} />}
                            </span>
                        </button>
                    </div>
                    <div className="col-md-3 text-center">
                        <button className="btn" onClick={() => nav("/advancedSearch")}>
                            <span>
                                <Translate id="courses.button.advancedSearch" />
                            </span>
                        </button>
                    </div>
                </div>
                {isFilterOpen && <div className="row">
                    <div className="col-md align-middle m-auto">
                        <label htmlFor="degreeLevel" className="row form-label text-center"><b><Translate id='courses.label.degreeLevel' /></b></label>
                        <SelectDegreeLevel value={selectedDegreeLevel} setValue={setSelectedDegreeLevel} />
                    </div>
                    <div className="col-md align-middle m-auto">
                        <label htmlFor="universityProgram" className="row form-label text-center"><b><Translate id='courses.label.program' /></b></label>
                        <SelectUniversityProgram value={selectedProgram} setValue={setSelectedProgram} />
                    </div>
                    <div className="col-md align-middle m-auto">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="activeSwitch" disabled={filterChanges} checked={filterActive} onChange={() => setIsActive(!filterActive)} />
                            <label className="form-check-label" htmlFor="activeSwitch">
                                {filterActive && <Translate id="courses.label.active"></Translate>}
                                {!filterActive && <Translate id="courses.label.inactive"></Translate>}
                            </label>
                        </div>
                    </div>
                    {(authContext.isAdmin || authContext.isLecturer) && <div className="col-md align-middle m-auto">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="activeSwitch" disabled={filterChanges} checked={filterOwn} onChange={() => setFilterOwn(!filterOwn)} />
                            <label className="form-check-label" htmlFor="activeSwitch">
                                {/* {filterOwn && <Translate id="courses.label.ownCourses"></Translate>} */}
                                <Translate id="courses.label.ownCourses"></Translate>
                                {/* {!filterOwn && <Translate id="courses.label.allCourses"></Translate>} */}
                            </label>
                        </div>
                    </div>}
                    {(authContext.isAdmin || authContext.isLecturer) && <div className="col-md align-middle m-auto">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="activeSwitch" checked={filterChanges} onChange={() => setFilterChanges(!filterChanges)} />
                            <label className="form-check-label" htmlFor="activeSwitch">
                                {/* {filterChanges && <Translate id="courses.label.withChanges"></Translate>} */}
                                <Translate id="courses.label.withChanges"></Translate>
                                {/* {!filterChanges && <Translate id="courses.label.allCourses"></Translate>} */}
                            </label>
                        </div>
                    </div>}
                </div>}
                <div className="row">
                    <Translate id="courses.label.numOfCourses" /> {listedCourses?.length ?? 0}
                </div>
                <div className="row">
                    <div className="col">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col"><Translate id="courses.table.header.courseId" /></th>
                                    <th scope="col"><Translate id="courses.table.header.courseName" /></th>
                                    <th scope="col"><Translate id="courses.table.header.department" /></th>
                                    <th scope="col"><Translate id="courses.table.header.creditNr" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listedCourses && listedCourses.map(
                                    course => (<tr key={course.courseId} onClick={() => nav(`course/${course.courseId}`)}>
                                            <td style={{color:"gray"}}>{course.courseId}</td>
                                            <td>{course.title} {course.englishTitle != undefined && `(${course.englishTitle})`}</td>
                                            <td>{course.department}</td>
                                            <td className="text-center">{course.creditNumber}</td>
                                    </tr>)
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>}
        </div>
    )
}