import { Dispatch } from "react";
import Translate from "../../i18n/messages/Translate";
import { DegreeLevels } from "../../generated/services/Backend";

interface SelectDegreeLevelProps{
    value: DegreeLevels | undefined
    setValue : Dispatch<DegreeLevels | undefined>
}

export default function SelectDegreeLevel(props: SelectDegreeLevelProps){

    return (<select className="form-select" id="degreeLevel" value={props.value ?? DegreeLevels.NotSpecified} onChange={event => {
        let level : DegreeLevels | undefined = event.target.value as unknown as DegreeLevels
        props.setValue(level);
    }}>
        {Object.keys(DegreeLevels).filter(k => isNaN(Number(k))).map(f =>
            <option key={f} value={f}><Translate id={`common.degreeLevels.${f}`} /></option>
        )};
    </select>)
}