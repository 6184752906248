import DOMPurify from "dompurify";
import { Dispatch, useState } from "react";
import Translate from "../../i18n/messages/Translate";
import { CourseDataV0Model } from "../../generated/services/Backend";
import AdvancedTextInput from "../common/AdvancedTextInput";

interface EditCourseDataV0ComponentsProps {
    course: CourseDataV0Model
    setCourse: Dispatch<CourseDataV0Model>
    onSubmit: Dispatch<string>
}

export default function EditCourseDataV0Components(props: EditCourseDataV0ComponentsProps) {
    const handleStringInputChange = (name: string, value: string) => {
        props.setCourse({ ...props.course, [name]: value } as CourseDataV0Model)
    }
    const [commitMessage, setCommitMessage] = useState<string>("");
    return (<div className="container">
        <div>
            <div className="row">
                <div className="col">
                    <h4><Translate id="course.page.instructors" /></h4>
                </div>
            </div>
            <div className="row">
                <AdvancedTextInput value={props.course.lecturers} onValueChange={value => handleStringInputChange("lecturers", value)} />
                {/* <textarea className="form-control" value={props.course.lecturers ?? ""} name="lecturers" onChange={handleStringInputChange}></textarea> */}
            </div>
            <div className="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.lecturers) }}>
            </div>
        </div>
        <div>
            <div className="row">
                <div className="col">
                    <h4><Translate id="course.page.requiredKnowledge" /></h4>
                </div>
            </div>
            <div className="row">
                <AdvancedTextInput value={props.course.requiredKnowledge} onValueChange={value => handleStringInputChange("requiredKnowledge", value)} />
                {/* <textarea className="form-control" value={props.course.requiredKnowledge ?? ""} name="requiredKnowledge" onChange={handleStringInputChange}></textarea> */}
            </div>
            <div className="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.requiredKnowledge) }}>
            </div>
        </div>
        <div>
            <div className="row">
                <div className="col">
                    <h4><Translate id="course.page.prerequisites" /></h4>
                </div>
            </div>
            <div className="row">
                <AdvancedTextInput value={props.course.preRequisites} onValueChange={value => handleStringInputChange("preRequisites", value)} />
                {/* <textarea className="form-control" value={props.course.preRequisites ?? ""} name="preRequisites" onChange={handleStringInputChange}></textarea> */}
            </div>
            <div className="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.preRequisites) }}>
            </div>
        </div>
        <div>
            <div className="row">
                <div className="col">
                    <h4><Translate id="course.page.objectives" /></h4>
                </div>
            </div>
            <div className="row">
                <AdvancedTextInput value={props.course.objectives} onValueChange={value => handleStringInputChange("objectives", value)} />
                {/* <textarea className="form-control" value={props.course.objectives ?? ""} name="objectives" onChange={handleStringInputChange}></textarea> */}
            </div>
            <div className="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.objectives) }}>
            </div>
        </div>
        <div>
            <div className="row">
                <div className="col">
                    <h4><Translate id="course.page.topics" /></h4>
                </div>
            </div>
            <div className="row">
                <AdvancedTextInput value={props.course.topics} onValueChange={value => handleStringInputChange("topics", value)} />
                {/* <textarea className="form-control" value={props.course.topics ?? ""} name="topics" onChange={handleStringInputChange}></textarea> */}
            </div>
            <div className="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.topics) }}>
            </div>
        </div>
        <div>
            <div className="row">
                <div className="col">
                    <h4><Translate id="course.page.methodOfInstruction" /></h4>
                </div>
            </div>
            <div className="row">
                <AdvancedTextInput value={props.course.instructionMethods} onValueChange={value => handleStringInputChange("instructionMethods", value)} />
                {/* <textarea className="form-control" value={props.course.instructionMethods ?? ""} name="instructionMethods" onChange={handleStringInputChange}></textarea> */}
            </div>
            <div className="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.instructionMethods) }}>
            </div>
        </div>
        <div>
            <div className="row">
                <div className="col">
                    <h4><Translate id="course.page.assessments" /></h4>
                </div>
            </div>
            <div className="row">
                <AdvancedTextInput value={props.course.assessments} onValueChange={value => handleStringInputChange("assessments", value)} />
                {/* <textarea className="form-control" value={props.course.assessments ?? ""} name="assessments" onChange={handleStringInputChange}></textarea> */}
            </div>
            <div className="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.assessments) }}>
            </div>
        </div>
        <div>
            <div className="row">
                <div className="col">
                    <h4><Translate id="course.page.recap" /></h4>
                </div>
            </div>
            <div className="row">
                <AdvancedTextInput value={props.course.recap} onValueChange={value => handleStringInputChange("recap", value)} />
                {/* <textarea className="form-control" value={props.course.recap ?? ""} name="recap" onChange={handleStringInputChange}></textarea> */}
            </div>
            <div className="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.recap) }}>
            </div>
        </div>
        <div>
            <div className="row">
                <div className="col">
                    <h4><Translate id="course.page.consultation" /></h4>
                </div>
            </div>
            <div className="row">
                <AdvancedTextInput value={props.course.consultation} onValueChange={value => handleStringInputChange("consultation", value)} />
                {/* <textarea className="form-control" value={props.course.consultation ?? ""} name="consultation" onChange={handleStringInputChange}></textarea> */}
            </div>
            <div className="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.consultation) }}>
            </div>
        </div>
        <div>
            <div className="row">
                <div className="col">
                    <h4><Translate id="course.page.references" /></h4>
                </div>
            </div>
            <div className="row">
                <AdvancedTextInput value={props.course.references} onValueChange={value => handleStringInputChange("references", value)} />
                {/* <textarea className="form-control" value={props.course.references ?? ""} name="references" onChange={handleStringInputChange}></textarea> */}
            </div>
            <div className="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.references) }}>
            </div>
        </div>
        <div>
            <div className="row">
                <div className="col">
                    <h4><Translate id="course.page.learningHours" /></h4>
                </div>
            </div>
            <div className="row">
                <AdvancedTextInput value={props.course.learningHours} onValueChange={value => handleStringInputChange("learningHours", value)} />
                {/* <textarea className="form-control" value={props.course.learningHours ?? ""} name="learningHours" onChange={handleStringInputChange}></textarea> */}
            </div>
            <div className="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.learningHours) }}>
            </div>
        </div>
        <div>
            <div className="row">
                <div className="col">
                    <h4><Translate id="course.page.preparedBy" /></h4>
                </div>
            </div>
            <div className="row">
                <AdvancedTextInput value={props.course.preparedBy} onValueChange={value => handleStringInputChange("preparedBy", value)} />
                {/* <textarea className="form-control" value={props.course.preparedBy ?? ""} name="preparedBy" onChange={handleStringInputChange}></textarea> */}
            </div>
            <div className="row" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.course.preparedBy) }}>
            </div>
        </div>
        <div className="row m-4 justify-content-center text-center">
            <div className="col-md-8">
                <label htmlFor="commmitMessage" className="form-label"><Translate id="course.page.commitMessage" /></label>
                <input type="text" id="commmitMessage" className="form-control" required value={commitMessage} onChange={(event) => { setCommitMessage(event.target.value) }} />
            </div>
            <div className="col-md-4">
                <button className="btn btn-success" type="submit" onClick={() => props.onSubmit(commitMessage)}><Translate id="addVersion.button.submit" /></button>
            </div>
        </div>
    </div>)
}